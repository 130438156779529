import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "single-bar-chart" }

import VueApexCharts from "vue3-apexcharts";


export default /*@__PURE__*/_defineComponent({
  __name: 'SingleBarChart',
  props: {
    labels: {},
    title: {},
    values: {},
    color: {},
    legendAlignment: {}
  },
  setup(__props: any) {

const props = __props;

const data = {
  // Chart data
  series: [
    {
      name: props.title,
      data: props.values,
      color: props.color,
    },
  ],
  chartOptions: {
    // chart options: type, height, stacked...
    chart: {
      type: "bar",
      stacked: false,
      parentHeightOffset: 0,
      offsetX: 0,
      offsetY: 0,
      toolbar: {
        show: false,
      },
    },
    // Customize chart values above the bars
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "14px",
        fontFamily: "Darker Grotesque",
        fontWeight: 700,
        colors: ["#111"],
      },
    },
    // Chart labels
    xaxis: {
      categories: props.labels,
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Darker Grotesque",
          fontWeight: 400,
          colors: new Array(props.labels.length).map(() => "#7a7a7a"),
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    // Legend options
    legend: {
      showForSingleSeries: true,
      showForZeroSeries: false,
      formatter: (value: string) => value.toLowerCase(),
      position: "bottom",
      horizontalAlign: props.legendAlignment ?? "center",
      offsetY: 0,
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Darker Grotesque",
      markers: {
        size: 7,
        shape: "circle",
        offsetX: -2,
      },
      inverseOrder: true,
    },
    // Configure default options for each type of chart
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "last",
        columnWidth: "30%",
        dataLabels: {
          position: "top",
        },
      },
    },
    // Responsiveness customization like bar thickness, radius, width...
    responsive: [
      {
        breakpoint: 450,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 6,
              columnWidth: "35%",
            },
          },
        },
      },
      {
        breakpoint: 500,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 6,
              columnWidth: "30%",
            },
          },
        },
      },
      {
        breakpoint: 993,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 4,
              columnWidth: "50%",
            },
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 6,
              columnWidth: "30%",
            },
          },
        },
      },
    ],
    // Removing default options
    tooltip: {
      enabled: false,
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    offsetX: 0,
    // Ended Removing default options
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(VueApexCharts), {
      type: "bar",
      height: "273",
      options: data.chartOptions,
      series: data.series
    }, null, 8, ["options", "series"])
  ]))
}
}

})