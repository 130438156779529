<script setup lang="ts">
import { ref } from "vue";
import {
  MeIcon,
  MeButton,
  MeTextField,
  MeOtpCode,
} from "@/libs/metha-components";
import { UpdateContactObject } from "@/types/UserTypes";
import CaModal from "@/components/atoms/CaModal.vue";
import { useUserStore } from "@/stores";
import { toast } from "vue3-toastify";
const userStore = useUserStore();
const props = defineProps<{
  type: string;
  closeButtonText?: string;
  closeModal: () => void;
}>();
const stage = ref(1);
const otpCode = ref("");
const contactUser = ref("");
const contactTitle = ref("");
const contactText = ref("");
const contactType = ref(props.type);
const email = ref("");
const phone = ref("");
const buttonText = ref(props.closeButtonText);
const texts: any = {
  email: {
    title: "Alterar meu e-mail",
    text: [
      "Digite seu novo e-mail abaixo:",
      "Enviamos um código de confirmação para o e-mail:",
    ],
  },
  phone: {
    title: "Alterar meu celular",
    text: [
      "Digite seu novo número de celular abaixo:",
      "Enviamos um código de confirmação para o celular:",
    ],
  },
};

function setContentText(type: string, stageNumber: number) {
  // stageNumber é referente a variável stage que é estágio que se encontra a etapa do formulário
  if (stageNumber === 1) {
    contactTitle.value = texts[type].title;
  } else if (stageNumber === 3) {
    contactTitle.value = "Informação de contato atualizada!";
    contactText.value =
      "Obrigado por manter suas informações atualizadas conosco!";
    buttonText.value = "Fechar";
  } else {
    contactText.value = texts[type].text[stageNumber - 1];
  }
}

async function updateContactUser() {
  userStore.loadingScreen = true;
  let payload = {
    type: contactType.value,
  } as UpdateContactObject;
  if (props.type === "email") {
    payload["value"] = email.value;
  } else {
    payload["value"] = phone.value;
  }
  payload["code"] = otpCode.value;
  const returnStatus = await userStore.updateContactUser(payload);
  if (returnStatus === "OK") {
    stage.value++;
  } else {
    email.value = "";
    phone.value = "";
    stage.value = 1;
  }
  setContentText(contactType.value, stage.value);
  userStore.loadingScreen = false;
}

async function updateContactSendOtpCode() {
  const sendOtpCode = await userStore.updateContactSendOtpCode({
    type: contactType.value,
    value: contactUser.value,
  });
  if (sendOtpCode.status === "error") initStage();
}

function warning() {
  toast.error("Preencha o campo corretamente!", {
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

function actions() {
  // stage é o estágio que se encontra a etapa do formulário
  // 1 é o preenchimento do contato, email ou telefone. 2 é o preenchimento do código Otp e 3 a resposta de sucesso.
  if (stage.value === 3) {
    props.closeModal();
    return;
  }
  if (stage.value === 2) {
    updateContactUser();
  }
  if (stage.value === 1) {
    const isPhoneAndEmpty = contactType.value === "phone" && phone.value === "";
    const isEmailAndEmpty = contactType.value === "email" && email.value === "";
    if (isEmailAndEmpty || isPhoneAndEmpty) {
      warning();
      return;
    }
    contactUser.value =
      contactType.value === "email" ? email.value : phone.value;
    stage.value++;
    setContentText(contactType.value, stage.value);
    updateContactSendOtpCode();
  }
}

function initStage() {
  email.value = "";
  phone.value = "";
  stage.value = 1;
  setContentText(contactType.value, stage.value);
}

setContentText(contactType.value, stage.value);
</script>

<template>
  <CaModal>
    <div class="contact-update-modal">
      <div class="contact-update-modal__close-button" @click="closeModal">
        <MeIcon>x</MeIcon>
      </div>
      <span v-if="stage === 3" class="contact-update-modal__icon">
        <MeIcon>check</MeIcon>
      </span>
      <h2 class="contact-update-modal__title">{{ contactTitle }}</h2>
      <p class="contact-update-modal__text" v-html="contactText"></p>
      <p
        v-if="stage === 2"
        class="contact-update-modal__text__bold"
        v-html="contactUser"
      ></p>
      <div class="contact-update-modal__content">
        <div v-if="stage === 1">
          <MeTextField
            v-if="type === 'email'"
            label="E-mail"
            name="email"
            v-model="email"
            rules="email|required"
            class="meTextField"
          />
          <MeTextField
            v-else
            label="Celular"
            name="phone"
            v-model="phone"
            v-maska="'(##) #####-####'"
            rules="required|phone"
            class="meTextField"
          />
        </div>
        <div v-if="stage === 2" class="meOtpCode">
          <p class="contact-update-modal__uppercase">código de autorização</p>
          <MeOtpCode v-model="otpCode" />
        </div>
        <MeButton
          v-if="closeButtonText && closeButtonText.length"
          @click="actions"
          size="large"
          block
          class="meButton"
        >
          {{ buttonText }}
        </MeButton>
      </div>
    </div>
  </CaModal>
</template>

<style lang="scss" scoped>
.meOtpCode {
  padding-top: 40px;
}

.meTextField {
  margin-top: 40px;
  @media (max-width: 993px) {
    padding-left: 40px;
    width: 280px;
  }
}

.meButton {
  margin-top: 24px;
  @media (max-width: 993px) {
    width: 240px;
  }
}
.contact-update-modal {
  text-align: center;
  margin: auto;
  width: 90%;
  max-width: 556px;
  background-color: #fff;

  border: none;
  border-radius: 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &__close-button {
    cursor: pointer;
    top: 15px;
    right: 17px;
    position: absolute;
    background-color: #e9ecef;
    color: #000;
    font-size: 0.675rem;
    font-weight: 700;
    width: 22px;
    height: 22px;
    padding: 0.675rem;
    border-radius: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    font-size: 2.25rem;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: hsl(89, 99%, 68%);
    color: #000;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }

  &__title {
    color: #000;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }

  &__text {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;

    &__bold {
      font-weight: 700;
    }

    @media (max-width: 993px) {
      width: 250px;
    }
  }

  &__uppercase {
    text-transform: uppercase;
    font-size: 16px;
    line-height: auto;
    padding-bottom: 10px;
    font-weight: 600;
  }

  &__content {
    width: 320px;
  }

  min-width: 528px;
  padding: 2.5rem 0 2.5rem 0;

  @media (max-width: 993px) {
    min-width: 360px;
  }
}
</style>
