import { emitNotification, hasAppWrapper } from "@/utils/appComunication";

export async function openLink(
  url: string,
  filename: string,
  elementToTeleport = "body"
) {
  if (!url) return;

  if (hasAppWrapper()) {
    emitNotification("openFile", { url, filename });
  } else {
    let parentElement = document.querySelector(elementToTeleport);
    if (!parentElement) parentElement = document.body;

    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("target", "__blank");
    element.setAttribute("download", filename);

    parentElement.appendChild(element);
    element.click();
    parentElement.removeChild(element);
  }
}
