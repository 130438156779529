import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "installation-nav-select-option" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "installation-nav-select-option__info" }
const _hoisted_4 = { class: "installation-nav-select-option__info__installation-number" }
const _hoisted_5 = { class: "installation-nav-select-option__info__address" }
const _hoisted_6 = { class: "installation-nav-select-option__icon" }

import { MeIcon } from "@/libs/metha-components";

import { FlatInstallation } from "@/types/InstallationTypes";
import { getSupplierLogo } from "@/utils/supplierlogo";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationNavSelectOption',
  props: {
    installation: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _unref(getSupplierLogo)(_ctx.installation.supplier),
      alt: `${_ctx.installation.supplier} logo`,
      class: "installation-nav-select-option__supplier-logo"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, " Instalação: " + _toDisplayString(_ctx.installation.installation_number), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.installation.address), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_unref(MeIcon), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("chevron-right")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})