<script setup lang="ts">
import { computed } from "vue";
import { getSupplierLogo } from "@/utils/supplierlogo";

import StatusTag from "@/components/atoms/StatusTag.vue";
import { Suppliers } from "@/types/Suppliers";

const props = defineProps<{
  status: string;
  installationNumber: string;
  address: string;
  supplier: Suppliers;
}>();

const supplierFullText = computed(() => {
  const suppliersText = {
    cemig: "CEMIG - Minas Gerais",
    "cpfl-paulista": "CPFL - São Paulo",
    "energisa-mt": "Energisa - Mato Grosso",
  };

  return suppliersText[props.supplier] || suppliersText.cemig;
});

const supplierLogo = computed(() => {
  return getSupplierLogo(props.supplier);
});
</script>

<template>
  <div class="installation-info">
    <div class="installation-info__header">
      <div class="installation-info__header__status">
        <StatusTag :status="status" />
      </div>
      <div class="installation-info__header__supplier-logo">
        <img :src="supplierLogo" :alt="`${supplierFullText} logo`" />
      </div>
    </div>
    <div class="installation-info__content">
      <div class="">
        <p class="installation-info__content__label">Instalação</p>
        <p class="installation-info__content__installation-number">
          {{ installationNumber }}
        </p>
      </div>
      <div>
        <p class="installation-info__content__label">Concessionária</p>
        <p class="installation-info__content__value">{{ supplierFullText }}</p>
      </div>
      <div>
        <p class="installation-info__content__label">Endereço</p>
        <p class="installation-info__content__value">
          {{ address }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.installation-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &__supplier-logo {
      width: 57px;
      height: fit-content;

      @media (min-width: 993px) {
        width: 69px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__row {
      display: flex;
      align-items: center;
    }

    &__installation-number {
      color: #111;
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.5rem;

      @media (min-width: 993px) {
        font-size: 1.375rem;
        line-height: 1.875rem;
      }
    }

    &__label {
      color: #7a7a7a;
      font-weight: 500;
      line-height: 1.5rem;
      font-size: 1rem;
    }

    &__value {
      color: #111111;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }
}
</style>
