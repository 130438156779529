<script setup lang="ts">
import {
  getInvoiceStatus,
  getInvoiceStatusColor,
  dangerStatuses,
} from "@/utils/status";

import { computed } from "vue";

const props = defineProps<{
  status: string;
}>();

const statusTranslated = computed(() => {
  if (!props.status) return "";

  return getInvoiceStatus(props.status).toLowerCase();
});

const statusColor = computed(() => getInvoiceStatusColor(props.status));

const statusTextColor = computed(() => {
  if (props.status && dangerStatuses.includes(props.status.toLowerCase())) {
    return "#fff";
  }

  return "#000";
});
</script>

<template>
  <div
    :style="{
      color: statusTextColor,
      'background-color': `rgba(${statusColor})`,
    }"
    class="status-tag"
  >
    fatura {{ statusTranslated }}
  </div>
</template>

<style lang="scss" scoped>
.status-tag {
  border: none;
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  text-align: center;
  width: 100%;
  height: min-content;
  align-self: center;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;

  @media (min-width: 993px) {
    font-size: 1.375rem;
    padding: 0.5rem 1rem;
  }
}
</style>
