import { Invoice } from "@/types/InvoiceTypes";

function orderByStatus(invoices: Invoice[]) {
  const order = {
    paid: 0,
    open: 1,
    queued: 2,
    on_hold: 3,
    to_render: 4,
    to_process: 5,
    canceled: 6,
    failed: 7,
    overdue: 8,
    default: 9,
  };

  const sortByOrder = (invoice: Invoice) => {
    const key = invoice.status as keyof typeof order;

    return order[key] ?? order["default"];
  };

  return invoices.sort(
    (prevInvoice, nextInvoice) =>
      sortByOrder(nextInvoice) - sortByOrder(prevInvoice)
  );
}

export function orderByReferenceMonth(
  invoices: Invoice[] | any[],
  ordenation: "ascending" | "descending" = "descending"
) {
  if (!invoices) return invoices;

  const orderedInvoices = [...invoices];

  const monthValue = {
    JAN: 1,
    FEV: 2,
    MAR: 3,
    ABR: 4,
    MAI: 5,
    JUN: 6,
    JUL: 7,
    AGO: 8,
    SET: 9,
    OUT: 10,
    NOV: 11,
    DEZ: 12,
  };

  if (ordenation === "ascending") {
    orderedInvoices.sort((invoiceA, invoiceB) => {
      const [monthA, yearA] = invoiceA.reference_month?.split("/") ?? [];
      const [monthB, yearB] = invoiceB.reference_month?.split("/") ?? [];

      if (yearA !== yearB) return +yearA - +yearB;

      const dateA = monthValue[monthA as keyof typeof monthValue];
      const dateB = monthValue[monthB as keyof typeof monthValue];

      return dateA - dateB;
    });
  } else if (ordenation === "descending") {
    orderedInvoices.sort((invoiceA, invoiceB) => {
      const [monthA, yearA] = invoiceA.reference_month?.split("/") ?? [];
      const [monthB, yearB] = invoiceB.reference_month?.split("/") ?? [];

      if (yearA !== yearB) return +yearB - +yearA;

      const dateA = monthValue[monthA as keyof typeof monthValue];
      const dateB = monthValue[monthB as keyof typeof monthValue];

      return dateB - dateA;
    });
  }

  return orderedInvoices;
}

function orderByDueDate(invoices: Invoice[]) {
  const orderedInvoices = [...invoices];

  orderedInvoices.sort((invoiceA, invoiceB) => {
    const dateA = new Date(invoiceA.due_date);
    const dateB = new Date(invoiceB.due_date);

    return +dateB - +dateA;
  });

  return orderedInvoices;
}

function orderByTotalValue(invoices: Invoice[]) {
  const orderedInvoices = [...invoices];

  orderedInvoices.sort((invoiceA, invoiceB) => {
    return invoiceB.total_value - invoiceA.total_value;
  });

  return orderedInvoices;
}

export function orderInvoices(invoices: Invoice[], parameter: string) {
  const ordenationFunctions = {
    status: orderByStatus,
    reference_month: orderByReferenceMonth,
    due_date: orderByDueDate,
    total_value: orderByTotalValue,
  };

  if (
    !invoices ||
    !invoices.length ||
    !Object.keys(ordenationFunctions).includes(parameter)
  )
    return invoices;

  const key = parameter as keyof typeof ordenationFunctions;
  return ordenationFunctions[key](invoices);
}
