import { ref } from "vue";

const oWebViewInterface = window.nsWebViewInterface;

const appStarted = ref(false);

function setAppStarted(value: boolean) {
  appStarted.value = value;
}

function emitNotification(event: string, payload?: any) {
  if (appStarted.value) oWebViewInterface?.emit(event, payload);
}

function listenNotificationFromApp(
  event: string,
  handler: (arg?: any) => void
) {
  oWebViewInterface?.on(event, handler);
}

function hasAppWrapper() {
  return appStarted.value;
}

export {
  setAppStarted,
  emitNotification,
  listenNotificationFromApp,
  hasAppWrapper,
};
