import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";
import {
  getInvoiceStatus,
  getInstallationStatus,
  getInvoiceStatusColor,
  getInstallationStatusColor,
} from "@/utils/status";


export default /*@__PURE__*/_defineComponent({
  __name: 'BulletStatusTag',
  props: {
    status: {},
    invoice: { type: Boolean },
    block: { type: Boolean },
    definedText: {}
  },
  setup(__props: any) {

const props = __props;

const hasDefinedText = computed(() => {
  return (props.definedText?.length ?? 0) > 0;
});

const statusTranslated = computed(() => {
  let translatedStatus = "";

  if (props.invoice) {
    translatedStatus = getInvoiceStatus(props.status);
  } else {
    translatedStatus = getInstallationStatus(props.status);
  }

  return translatedStatus;
});

const statusColor = computed(() => {
  if (props.invoice) {
    return getInvoiceStatusColor(props.status);
  }

  return getInstallationStatusColor(props.status);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`--bullet-status-tag-status-color: rgb(${statusColor.value});`),
    class: "bullet-status-tag"
  }, [
    _createElementVNode("span", {
      style: _normalizeStyle({ 'background-color': `rgb(${statusColor.value})` }),
      class: "bullet-status-tag__ball"
    }, null, 4),
    _createElementVNode("span", null, _toDisplayString(hasDefinedText.value ? _ctx.definedText : statusTranslated.value), 1)
  ], 4))
}
}

})