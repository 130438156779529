import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "adc__invoices__section invoices-list" }
const _hoisted_2 = { class: "invoices-list__filters" }
const _hoisted_3 = { class: "invoices-list__filters__select-year" }
const _hoisted_4 = { class: "invoices-list__filters__select-order" }
const _hoisted_5 = { class: "invoices-list__info" }
const _hoisted_6 = { class: "invoices-list__info__icon" }

import { ref, computed, readonly, onBeforeMount } from "vue";

import { MeIcon, useBreakpoints } from "@/libs/metha-components";

import { Invoice } from "@/types/InvoiceTypes";
import InvoicesListTable from "@/components/molecules/InvoicesListTable.vue";
import CaSelect from "@/components/atoms/CaSelect.vue";
import { orderInvoices } from "@/utils/orderInvoices";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesList',
  props: {
    invoices: {}
  },
  setup(__props: any) {

const props = __props;

const { lgAndUp } = useBreakpoints();
const year = ref<number | string>("Últimas 12 faturas");
const yearOptions = ref<Array<number | string>>(["Últimas 12 faturas"]);

const ordenationParameter = ref("reference_month");
const orderOptions = readonly([
  {
    name: "Mês Referência",
    value: "reference_month",
  },
  {
    name: "Vencimento",
    value: "due_date",
  },
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Valor",
    value: "total_value",
  },
]);

const orderedInvoices = computed(() => {
  let filteredInvoices = props.invoices;
  let hasYearFilter = typeof year.value === "number";

  if (hasYearFilter) {
    filteredInvoices = props.invoices.filter(({ reference_month }) => {
      const [, invoiceYear] = reference_month.split("/");

      return +invoiceYear === year.value;
    });
  }

  filteredInvoices = orderInvoices(filteredInvoices, ordenationParameter.value);

  return hasYearFilter ? filteredInvoices : filteredInvoices.slice(0, 12);
});

function getNameForCaSelectYear(item: number) {
  return item;
}

function getValueForCaSelectYear(item: number) {
  return item;
}

onBeforeMount(() => {
  for (let i = 0; i < props.invoices.length; i += 1) {
    const { reference_month } = props.invoices[i];
    const [, invoiceYear] = reference_month.split("/");

    yearOptions.value = [...new Set([...yearOptions.value, +invoiceYear])];
  }

  yearOptions.value.sort((a, b) => {
    if (typeof a === "string") return -1;
    if (typeof b === "string") return 1;

    return b - a;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "invoices-list__title" }, "Lista de Faturas", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("span", null, [
          _createVNode(CaSelect, {
            label: "Filtrar",
            modelValue: year.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((year).value = $event)),
            options: yearOptions.value,
            optionsSide: _unref(lgAndUp) ? 'right' : 'left',
            getName: getNameForCaSelectYear,
            getValue: getValueForCaSelectYear
          }, null, 8, ["modelValue", "options", "optionsSide"])
        ])
      ]),
      _createElementVNode("p", _hoisted_4, [
        _createElementVNode("span", null, [
          _createVNode(CaSelect, {
            label: "Ordenar",
            modelValue: ordenationParameter.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ordenationParameter).value = $event)),
            options: _unref(orderOptions),
            optionsSide: "right"
          }, null, 8, ["modelValue", "options"])
        ])
      ])
    ]),
    _createVNode(InvoicesListTable, { invoices: orderedInvoices.value }, null, 8, ["invoices"]),
    _createElementVNode("p", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("alert-circle")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "invoices-list__info__text" }, " As faturas negociadas ou em negociação não constam nesta lista. ", -1))
    ])
  ]))
}
}

})