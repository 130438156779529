import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "faq-section-card" }
const _hoisted_2 = {
  key: 0,
  class: "faq-section-card__loading"
}
const _hoisted_3 = { class: "faq-section-card__title" }
const _hoisted_4 = { class: "faq-section-card__container" }
const _hoisted_5 = {
  key: 0,
  class: "faq-section-card__line"
}
const _hoisted_6 = {
  key: 0,
  class: "faq-section-card__line"
}

import { computed, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";

import { toastMessage } from "@/libs/toast";

import { Article, Section } from "@/types/Faq";
import { useFaqStore } from "@/stores/faq";
import { useUserStore } from "@/stores/user";

import FaqCardItem from "../atoms/FaqCardItem.vue";
import CaSpinner from "../atoms/CaSpinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqSectionCard',
  props: {
    section: {},
    maxArticles: {}
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const faqStore = useFaqStore();
const userStore = useUserStore();

const articles = ref<Article[]>([]);
const isOpen = ref(false);
const loading = ref(false);

const maxArticles = ref(props.maxArticles ?? 4);

const displayedArticles = computed(() => {
  return articles.value.slice(0, maxArticles.value);
});

const hiddenArticles = computed(() => {
  return articles.value.slice(maxArticles.value);
});

const hasExpandButton = computed(() => {
  return articles.value.length > maxArticles.value;
});

async function setCurrentArticle(article: Article) {
  try {
    userStore.loadingScreen = true;
    await faqStore.setCurrentSection(props.section.id);
    router.push(`central-de-ajuda/${article.id}`);
  } catch (error) {
    toastMessage(
      "error",
      "Ocorreu um erro ao selecionar o artigo. Tente novamente por favor"
    );
  } finally {
    userStore.loadingScreen = false;
  }
}

onBeforeMount(async () => {
  loading.value = true;
  articles.value = await faqStore.getArticles(props.section.id);
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(CaSpinner, {
            size: 20,
            width: 5
          })
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.section.name), 1),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(displayedArticles.value, (article, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `faq-section-${_ctx.section.name}-card-${index}`
              }, [
                _createVNode(FaqCardItem, {
                  article: article,
                  setCurrentArticle: setCurrentArticle
                }, null, 8, ["article"]),
                (index < displayedArticles.value.length - 1 || isOpen.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hiddenArticles.value, (article, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: `faq-section-${_ctx.section.name}-card-${index}`
              }, [
                _createVNode(FaqCardItem, {
                  article: article,
                  setCurrentArticle: setCurrentArticle
                }, null, 8, ["article"]),
                (index < hiddenArticles.value.length - 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                  : _createCommentVNode("", true)
              ])), [
                [_vShow, isOpen.value]
              ])
            }), 128))
          ]),
          (hasExpandButton.value)
            ? (_openBlock(), _createElementBlock("p", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value)),
                class: "faq-section-card__expander"
              }, _toDisplayString(isOpen.value ? "Menos" : "Mais") + " artigos ", 1))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}
}

})