import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "barcode-payment__copy" }
const _hoisted_2 = {
  key: 0,
  class: "barcode-payment__line__icon"
}
const _hoisted_3 = { class: "barcode-payment__line__value" }
const _hoisted_4 = { class: "barcode-payment__readable" }

import BarcodeGenerator from "vue3-barcode";
import { useBreakpoints, MeIcon } from "@/libs/metha-components";

import CopyContainer from "@/components/atoms/CopyContainer.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BarcodePayment',
  props: {
    barcode: {}
  },
  setup(__props: any) {



const { mdAndUp } = useBreakpoints();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(CopyContainer, {
        valueToCopy: _ctx.barcode,
        disabled: !_ctx.barcode,
        successMessage: "Código de barras copiado!"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([
          'barcode-payment__line',
          { 'barcode-payment__line--disabled': !_ctx.barcode },
        ])
          }, [
            (!_unref(mdAndUp) && _ctx.barcode)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                  _createVNode(_unref(MeIcon), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("reader-barcode")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.barcode
              ? _ctx.barcode
              : "O pagamento por código de barras não está disponível para esta fatura."), 1)
          ], 2)
        ]),
        _: 1
      }, 8, ["valueToCopy", "disabled"])
    ]),
    _createElementVNode("p", _hoisted_4, [
      (_ctx.barcode)
        ? (_openBlock(), _createBlock(_unref(BarcodeGenerator), {
            key: 0,
            value: _ctx.barcode.replace(' ', ''),
            height: 77,
            width: 1,
            displayValue: false
          }, null, 8, ["value"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})