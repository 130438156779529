import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "faq-card-item__title" }
const _hoisted_2 = { class: "faq-card-item__icon" }

import { MeIcon } from "@/libs/metha-components";
import { Article } from "@/types/Faq";


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqCardItem',
  props: {
    article: {},
    setCurrentArticle: { type: Function }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "faq-card-item",
    onClick: _cache[0] || (_cache[0] = () => _ctx.setCurrentArticle(_ctx.article))
  }, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.article.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(MeIcon), null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("chevron-right")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})