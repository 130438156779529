import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoices-not-found__container" }
const _hoisted_2 = { class: "invoices-not-found__icon" }
const _hoisted_3 = ["src"]

import ChatInfo from "@/assets/icons/chart-info.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesNotFound',
  props: { margin: Boolean },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['invoices-not-found', { 'invoices-not-found--margin': __props.margin }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", { src: _unref(ChatInfo) }, null, 8, _hoisted_3)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "invoices-not-found__text" }, [
        _createElementVNode("p", null, [
          _createElementVNode("b", null, "Você não possui nenhuma fatura Metha Energia."),
          _createElementVNode("br"),
          _createElementVNode("br"),
          _createTextVNode(" Após a aprovação do seu cadastro, nossa energia limpa será enviada para você, e as faturas ficarão disponíveis aqui. ")
        ])
      ], -1))
    ])
  ], 2))
}
}

})