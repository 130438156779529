import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/tile-black-background-metha-logo.png'


const _hoisted_1 = { class: "client-economy" }
const _hoisted_2 = { class: "client-economy__value" }

import { formatMoney } from "@/utils/format";


export default /*@__PURE__*/_defineComponent({
  __name: 'ClientEconomy',
  props: {
    totalEconomy: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<img src=\"" + _imports_0 + "\" alt=\"\" class=\"client-economy__graphism\" data-v-1acaa756><div class=\"client-economy__header\" data-v-1acaa756><p class=\"client-economy__header__title\" data-v-1acaa756>Economia</p><div class=\"client-economy__header__sustainability-icon\" data-v-1acaa756><span data-v-1acaa756>$</span></div></div><p class=\"client-economy__text\" data-v-1acaa756> Desde que se tornou cliente Metha, você já economizou </p>", 3)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(formatMoney)(_ctx.totalEconomy)), 1)
  ]))
}
}

})