<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import { MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores";

const props = defineProps({
  icon: String,
  title: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    required: true,
  },
  active: Boolean,
});

const router = useRouter();
const userStore = useUserStore();

const hasIcon = computed(() => {
  return typeof props.icon === "string" && props.icon.trim().length > 0;
});

function redirect() {
  if (props.active) return;
  userStore.switchSidebarMenu();
  router.push(props.route);
}
</script>

<template>
  <div
    :class="[
      'sidebar-tabs',
      {
        'sidebar-tabs--active': active,
        'sidebar-tabs--clickable': !active,
      },
    ]"
    @click="redirect"
  >
    <span class="sidebar-tabs__icon" v-if="hasIcon"
      ><MeIcon>{{ icon }}</MeIcon></span
    >
    {{ title }}
  </div>
</template>

<style lang="scss" scoped>
.sidebar-tabs {
  background: #fff;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  width: 100%;
  padding: 1rem;

  color: #000;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;

  @media (min-width: 993px) {
    gap: 0.25rem;
  }

  &--active {
    background: #000;
    color: #fff;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background: #e9ecef;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
    font-size: 1.5rem;
  }
}
</style>
