import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import { toast } from "vue3-toastify";

import {
  useAuthStore,
  useLoginStore,
  useInvoicesStore,
  usePersistentAuthStore,
  useWarningStore,
  useContractStore,
} from "@/stores";

import type {
  SetUserObject,
  UpdateUserObject,
  UpdateContactObject,
} from "@/types/UserTypes";
import type { Installation, FlatInstallation } from "@/types/InstallationTypes";

import {
  flatInstallationsData,
  filterInstallationsByStatus,
  operationalInstallationStatuses,
} from "@/utils/installations";
import { handleError } from "@/utils/handleError";

import api from "@/api";
import { disableSmooch } from "@/plugins/sunshineChat";
import { orderConsumptionHistory } from "@/utils/consumptionHistory";

const initialUserState = () => ({
  id: "",
  email: "",
  name: "",
  cpf: "",
  phone: "",
  birthdate: undefined as Date | undefined,
  rg: "",
  occupation: "",
  maritalStatus: "",
  token: "",
  pageTitle: "",
  isSidebarOpen: false,
  pageLoading: false,
  loadingError: "",
  installations: [] as FlatInstallation[],
  operationalInstallations: [] as FlatInstallation[],
  selectedInstallation: {} as FlatInstallation,
  installationConsumptionHistory: {
    labels: [] as string[],
    methaValues: [] as number[],
    supplierValues: [] as number[],
    totalValues: [] as number[],
  },
  loadingScreen: false,
  otpError: "",
  loadingOtp: false,
  openSunshineChat: () => {
    return;
  },
  calledOpenChat: false,
});

export const useUserStore = defineStore("user", {
  state: () => useSessionStorage("@metha-energia/adc-user", initialUserState()),
  actions: {
    async logOutUser({ maintainLogin } = { maintainLogin: false }) {
      const authStore = useAuthStore();
      const contractStore = useContractStore();
      const invoicesStore = useInvoicesStore();
      const loginStore = useLoginStore();
      const persistentAuthStore = usePersistentAuthStore();
      const warningStore = useWarningStore();

      if (!maintainLogin) {
        persistentAuthStore.logOut();
        warningStore.clear();
      }

      await Promise.all([
        this.clear(),
        authStore.clear(),
        contractStore.clear(),
        invoicesStore.clear(),
        loginStore.clear(),
        disableSmooch(),
      ]);
    },
    async clear() {
      Object.assign(this, initialUserState());
    },
    setUser(userData: SetUserObject) {
      const persistentAuthStore = usePersistentAuthStore();

      if (userData.civil_status) {
        this.maritalStatus = userData.civil_status;
      }

      if (userData.cpf) {
        persistentAuthStore.setDocument(userData.cpf);
      }

      let email = userData.email;
      let phone = userData.phone;

      if (Array.isArray(userData.email)) {
        email = userData.email[0];
      }

      if (Array.isArray(userData.phone)) {
        phone = userData.phone[0];
      }

      Object.assign(this, {
        ...userData,
        email,
        phone,
      });
    },
    async getUser() {
      try {
        const authStore = useAuthStore();
        const token = await authStore.getToken("userStoreGetUser");

        const { data } = await api.user.getUser(
          { cpf: this.cpf },
          {},
          {
            headers: {
              Authorization: authStore.get(),
              "g-recaptcha-response": token,
            },
          }
        );

        this.setUser(data);

        await this.getUserInstallations(data.id);
      } catch (error) {
        handleError(error, this.setLoadingError);
      }
    },
    async getUserInstallations(userId: string) {
      try {
        const authStore = useAuthStore();
        const token = await authStore.getToken("userStoreGetUser");

        const { data } = await api.installation.getInstallations(
          userId,
          {},
          {
            headers: {
              Authorization: authStore.get(),
              "g-recaptcha-response": token,
            },
          }
        );

        this.setOperationalInstallations(data);
        this.setInstallations(data);
      } catch (error) {
        handleError(error, this.setLoadingError);
      }
    },
    async updateUser(payload: UpdateUserObject) {
      const authStore = useAuthStore();

      try {
        const token = await authStore.getToken("userStoreUpdateUser");

        await api.user.updateUser(
          payload,
          {},
          {
            headers: {
              Authorization: authStore.get(),
              "g-recaptcha-response": token,
            },
          }
        );

        toast.success("Os dados da conta foram atualizados com sucesso!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        this.getUser();
      } catch (error) {
        handleError(error, (err: string) => {
          toast.error(err, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
      }
    },
    async updateContactSendOtpCode(data: { type: string; value: string }) {
      try {
        const authStore = useAuthStore();
        const token = await authStore.getToken("loginStoreSendOtpCode");

        this.setOtpError("");

        const payload: any = {
          cpf: this.cpf,
        };
        if (data.type === "phone") {
          payload["phone"] = data.value;
        } else {
          payload["email"] = data.value;
        }

        await api.user.updateContactSendOtpCode(
          payload,
          {},
          {
            headers: {
              Authorization: authStore.get(),
              "g-recaptcha-response": token,
            },
          }
        );
        return { status: "success" };
      } catch (error) {
        handleError(error, (err: string) => {
          toast.error(err, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
        return { status: "error" };
      }
    },
    setOtpError(message: string) {
      this.otpError = message;
    },
    async updateContactUser(payload: UpdateContactObject) {
      const authStore = useAuthStore();
      try {
        const token = await authStore.getToken("userStoreUpdateUser");
        payload["cpf"] = this.cpf;
        await api.user.updateContactUser(
          payload,
          {},
          {
            headers: {
              Authorization: authStore.get(),
              "g-recaptcha-response": token,
            },
          }
        );

        toast.success("Os dados da conta foram atualizados com sucesso!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });

        this.getUser();
        return "OK";
      } catch (error) {
        handleError(error, (err: string) => {
          toast.error(err, {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
        return "ERROR";
      }
    },
    findInstallationById(id: string | null) {
      if (!id) return null;

      const installations = this.installations ?? [];
      return installations.find((installation) => installation.id === id);
    },
    setOperationalInstallations(
      rawInstallations: Array<Installation | FlatInstallation>
    ) {
      const operationalInstallations = filterInstallationsByStatus(
        rawInstallations,
        operationalInstallationStatuses
      );

      this.operationalInstallations = flatInstallationsData(
        operationalInstallations
      );
    },
    setInstallations(installations: Array<Installation | FlatInstallation>) {
      const flattedInstallations = flatInstallationsData(installations);
      this.installations = flattedInstallations;
    },
    async selectInstallation(installation: FlatInstallation) {
      const persistentAuthStore = usePersistentAuthStore();
      persistentAuthStore.setLastSessionInstallationId(String(installation.id));
      this.selectedInstallation = installation;
    },
    async switchInstallation(installation: FlatInstallation) {
      if (installation.id === this.selectedInstallation.id) return;

      try {
        this.pageLoading = true;
        const invoicesStore = useInvoicesStore();

        await invoicesStore.getAllInvoices(installation.id);

        this.selectInstallation(installation);

        await this.getInstallationConsumptionHistory();
      } catch (error) {
        handleError(error, this.setLoadingError);
      } finally {
        this.pageLoading = false;
      }
    },
    setToken(token: string) {
      this.token = token;
    },
    setPageTitle(title: string) {
      this.pageTitle = title;
    },
    switchSidebarMenu() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    setLoadingError(err: string) {
      this.loadingError = err;
    },
    setOpenSunshineChat(openChat: () => void) {
      this.openSunshineChat = openChat;
    },
    async getInstallationConsumptionHistory() {
      const installation = this.selectedInstallation;

      if (!installation) return;

      const authStore = useAuthStore();
      const token = await authStore.getToken("userStoreGetConsumptionHistory");

      const { data } = await api.installation.getConsumptionHistory(
        installation.installation_number,
        {},
        {
          headers: {
            Authorization: authStore.get(),
            "g-recaptcha-response": token,
          },
        }
      );

      const orderedConsumptionHistory = orderConsumptionHistory(data);

      const labels: string[] = [];
      const methaValues: number[] = [];
      const supplierValues: number[] = [];
      const totalValues: number[] = [];

      orderedConsumptionHistory.forEach(
        ({ month, kwh_consumption, kwh_injected, total_value }) => {
          const totalValue = total_value ?? 0;

          labels.push(month);
          methaValues.push(kwh_injected);
          supplierValues.push(kwh_consumption - kwh_injected);
          totalValues.push(Math.floor(totalValue));
        }
      );

      this.installationConsumptionHistory = {
        labels,
        methaValues,
        supplierValues,
        totalValues,
      };
    },
  },
});
