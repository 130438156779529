import { Contract, ContractFile } from "@/types/Contract";
import { FlatInstallation } from "@/types/InstallationTypes";

const allowedStatuses = {
  updateContract: [
    "active",
    "approved",
    "pending_agreement",
    "pending_update",
    "suspended",
  ],
};

export function getContractLink(filename: string) {
  const bucket = process.env.VUE_APP_CONTRACTS_BUCKET ?? "metha-contracts";

  return `https://${bucket}.s3.sa-east-1.amazonaws.com/${filename}.pdf`;
}

export function getDocusignContractFile(contract: Contract): ContractFile {
  const contractId = contract.contract_id;
  const url = getContractLink(`${contractId}`);

  return {
    url,
    name: `Termos de uso Metha Energia - ${contractId}`,
  };
}

export function getMethasignContractFiles(contract: Contract): ContractFile[] {
  const contractId = `signed/${contract.contract_id}`;

  const adesao = getContractLink(`${contractId}_adesao`);
  const comercializacao = getContractLink(`${contractId}_comercializacao`);

  return [
    { url: adesao, name: `Termo de Adesão Metha Energia - ${contractId}` },
    {
      url: comercializacao,
      name: `Termo de Comercialização Metha Energia - ${contractId}`,
    },
  ];
}

export function canSignContract(installation: FlatInstallation) {
  if (!allowedStatuses.updateContract.includes(installation.status))
    return false;

  return (
    installation.status === "pending_agreement" ||
    installation.pendency_contract_update
  );
}
