<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { MeIcon } from "@/libs/metha-components";
import { toastMessage } from "@/libs/toast";

import { InputFileEvent } from "@/types/HTMLEventHandling";
import { useFaqStore } from "@/stores/faq";
import debounce from "@/utils/debounce";

import CaSpinner from "@/components/atoms/CaSpinner.vue";
import { useUserStore } from "@/stores/user";
import { SearchedArticle } from "@/types/Faq";

const router = useRouter();

const faqStore = useFaqStore();
const userStore = useUserStore();

const searchParam = ref("");
const loading = ref(false);
const isOpen = ref(false);

const results = computed(() => {
  return faqStore.searchResults ?? [];
});

function resetSearchBar() {
  searchParam.value = "";
  loading.value = false;
  isOpen.value = false;
  faqStore.resetSearchBar();
}

function debouncedFunction(arg: string) {
  const debounced = debounce(async () => {
    search(arg);
  }, 800);

  debounced();
}

async function search(param: string) {
  if (loading.value) return;
  loading.value = true;

  await faqStore.searchArticles(param);

  loading.value = false;
}

function handleInput(e: Event) {
  e.preventDefault();

  const event = e as InputFileEvent;

  searchParam.value = event.target.value;
}

function openSearchResults() {
  isOpen.value = true;
}

function closeSearchResults() {
  isOpen.value = false;
}

function formatSnippet(html: string) {
  const highlightSearchedTerm =
    '<em style="font-weight: 700 !important; font-style: italic;"';

  return html.replace("<em", highlightSearchedTerm);
}

async function switchSelectedArticle(article: SearchedArticle) {
  try {
    userStore.loadingScreen = true;
    await faqStore.setCurrentSection(article.section_id);

    router.push(`/central-de-ajuda/${article.id}`);

    resetSearchBar();
  } catch (error) {
    toastMessage(
      "error",
      "Ocorreu um erro ao selecionar o artigo. Tente novamente por favor"
    );
  } finally {
    userStore.loadingScreen = false;
  }
}

watch(
  () => searchParam.value,
  (newVal: string) => {
    if (newVal.length === 0) {
      faqStore.clearSearchResults();
    } else {
      debouncedFunction(newVal);
    }
  }
);
</script>

<template>
  <div
    :class="[`faq-search-bar`, { 'faq-search-bar--is-open': isOpen }]"
    v-click-outside="closeSearchResults"
  >
    <div class="faq-search-bar__container">
      <span class="faq-search-bar__icon">
        <MeIcon>search</MeIcon>
      </span>
      <input
        type="text"
        placeholder="Digite sua dúvida..."
        :disabled="loading"
        class="faq-search-bar__input"
        v-model="searchParam"
        @click="openSearchResults"
        @input="handleInput"
      />
    </div>
    <div class="faq-search-bar__results" v-if="faqStore.searchRequestMade">
      <template v-if="loading">
        <h4 class="faq-search-bar__results__loading">
          <div class="faq-search-bar__results__loading__spinner">
            <CaSpinner :size="14" :width="2" />
          </div>
          Carregando...
        </h4>
      </template>
      <template v-else-if="!results || results?.length === 0">
        <h4 class="faq-search-bar__results__item__title--error">
          Não foi encontrado nenhum resultado para essa pesquisa.
        </h4>
      </template>
      <template v-else>
        <div
          class="faq-search-bar__results__item"
          v-for="(item, index) in results"
          @click="() => switchSelectedArticle(item)"
          :key="`faq-search-results-item-${index}`"
        >
          <h4 class="faq-search-bar__results__item__title">
            {{ item.title }}
          </h4>
          <p
            class="faq-search-bar__results__item__description"
            v-html="formatSnippet(item.snippet)"
          ></p>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.faq-search-bar {
  max-width: 950px;
  width: calc(100% - 2rem);
  margin: 0 auto;

  position: relative;

  @media (min-width: 1020px) {
    max-width: 1180px;
    width: calc(100% - 6rem);
  }

  &__container {
    position: relative;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);

    color: #6c757d;
    font-size: 32px;
  }

  &__input {
    padding: 1rem 1.5rem 1rem calc(1.5rem + 48px);
    background: #fff;
    width: 100%;

    color: #6c757d;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;

    border-radius: 2rem;
    border: 1px solid #afafaf;
    outline: none;

    &:active,
    &:focus {
      color: #000;
      border-color: #000;

      .faq-search-bar__icon {
        color: #000;
      }
    }
  }

  &__results {
    opacity: 0;
    z-index: -1;
    box-shadow: 0px 0px 1px 0px #21252952;
    box-shadow: 0px 4px 6px 0px #21252933;
    border: 1px solid #ebebeb;
    border-radius: 1rem;
    background: #fff;

    position: absolute;
    top: calc(20px + 2rem + 1rem);
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding: 1.5rem 2rem;

    &__item {
      display: flex;
      flex-direction: column;

      color: #000;
      font-weight: 500;

      &__title {
        font-size: 1.125rem;
        font-weight: inherit;
        line-height: 1.6875rem;
        text-decoration: underline;
      }

      &__description {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
      }

      &:hover {
        font-weight: 600;
        cursor: pointer;
      }
    }

    &__loading {
      width: fit-content;
      margin: 0 auto;
      padding-right: 4rem;
      display: flex;
      align-items: center;
      gap: 10px;

      &__spinner {
        position: relative;

        display: flex;
        align-items: center;

        width: 12px;
        height: 12px;
      }
    }
  }

  &--is-open {
    .faq-search-bar__results {
      opacity: 1;
      z-index: 4;
    }
  }
}
</style>
