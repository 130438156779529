import { ConsumptionHistoryItem } from "@/types/InstallationTypes";
import { orderByReferenceMonth } from "./orderInvoices";

export function orderConsumptionHistory(history: ConsumptionHistoryItem[]) {
  let orderedItems = [...history];

  orderedItems = orderByReferenceMonth(orderedItems, "ascending");

  return orderedItems;
}

const months = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];

function getNextMonth(month: string) {
  const monthIndex = months.findIndex((monthName) => month === monthName);

  if (monthIndex === months.length - 1) {
    return "JAN";
  }

  return months[monthIndex + 1];
}

export function fillEmptySlotsInConsumptionHistory(
  chartInfo: any,
  lengthGoal = 6
) {
  let securityLock = 0;

  const filledChartInfo = {
    ...chartInfo,
  };

  while (
    filledChartInfo.labels.length < lengthGoal &&
    securityLock < lengthGoal * 2
  ) {
    const nextMonth = getNextMonth(
      filledChartInfo.labels[filledChartInfo.labels.length - 1]
    );

    filledChartInfo.labels.push(nextMonth);

    securityLock += 1;
  }

  return filledChartInfo;
}
