<script setup lang="ts">
import { onBeforeUnmount, ref } from "vue";
import useClipboard from "vue-clipboard3";

const props = defineProps({
  valueToCopy: {
    type: [String, Number],
    required: true,
  },
  successMessage: {
    type: String,
    default: "Copiado com sucesso!",
  },
  timeToReturnToBaseModel: {
    type: Number,
    default: 1000,
  },
  disabled: Boolean,
});

const { toClipboard } = useClipboard();

const copied = ref(false);
const timeout = ref<ReturnType<typeof setTimeout> | null>(null);

function returnToBaseModel() {
  try {
    const waitingTime = 3 * props.timeToReturnToBaseModel;

    timeout.value = setTimeout(() => {
      copied.value = false;
    }, waitingTime);
  } catch (error) {
    copied.value = false;
  }
}

async function copyToClipboard() {
  if (copied.value || props.disabled) return;

  try {
    await toClipboard(String(props.valueToCopy));
    copied.value = true;
    returnToBaseModel();
  } catch (e) {
    console.error(e);
    copied.value = false;
  }
}

onBeforeUnmount(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
});
</script>

<template>
  <div class="copy-container">
    <div
      :class="[
        'copy-container__success',
        { 'copy-container__success--show': copied },
      ]"
    >
      {{ successMessage }}
    </div>
    <div @click="copyToClipboard">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.copy-container {
  &__success {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    background-color: #2db699;
    color: #fff;
    font-size: 16px;

    opacity: 0;
    z-index: -1;

    &--show {
      opacity: 1;
      z-index: 2;
      cursor: auto;
    }
  }
}
</style>
