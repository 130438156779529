<script setup lang="ts">
import { ref, computed, PropType } from "vue";

import { MeIcon, useBreakpoints } from "@/libs/metha-components";

export interface CaSelectOption {
  name: string;
  value: string | number;
}

const props = defineProps({
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  options: {
    type: Array as PropType<
      ReadonlyArray<CaSelectOption> | Array<string | number>
    >,
    required: true,
  },
  getValue: {
    type: Function,
    default: (item: number | string | CaSelectOption) => {
      if (typeof item === "object") {
        return item.value;
      }

      return item;
    },
  },
  getName: {
    type: Function,
    default: (item: number | string | CaSelectOption) => {
      if (typeof item === "object") {
        return item.name;
      }

      return item;
    },
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  optionsSide: {
    type: String as PropType<"left" | "right">,
    default: "left",
  },
});

const emit = defineEmits(["update:modelValue"]);

const { lgAndUp } = useBreakpoints();
const isOpen = ref(false);

const selectedOption = computed(() => {
  let selectedItem = null;

  for (let i = 0; i < props.options.length; i += 1) {
    const currentItem = props.options[i];
    if (props.getValue(currentItem) === props.modelValue) {
      selectedItem = currentItem;
      break;
    }
  }

  return selectedItem;
});

const highlightedValue = computed(() => {
  if (!lgAndUp.value && (props.label?.length ?? 0) > 0) {
    return props.label;
  }

  return props.getName(selectedOption.value);
});

const computedStyle = computed(() => {
  return `${props.optionsSide}: 0;`;
});

function handleChange(item: CaSelectOption | string | number) {
  const value = props.getValue(item);
  emit("update:modelValue", value);
  isOpen.value = false;
}

function closeSelect() {
  isOpen.value = false;
}
</script>

<template>
  <div class="ca-select" v-click-outside="closeSelect">
    <div class="ca-select__label-container" @click="isOpen = !isOpen">
      <p v-if="icon && icon.length" class="ca-select__icon">
        <MeIcon>{{ icon }}</MeIcon>
      </p>
      <p class="ca-select__label" v-if="label && label.length">{{ label }}:</p>
      <p class="ca-select__selected-option">
        {{ highlightedValue }}
      </p>
      <span
        :class="[
          'ca-select__switch-icon',
          { 'ca-select__switch-icon--open': isOpen },
        ]"
      >
        <MeIcon> chevron-bottom </MeIcon>
      </span>
    </div>
    <div class="ca-select__options" :style="computedStyle" v-if="isOpen">
      <div
        v-for="(item, index) in options"
        :key="`${index}`"
        @click="() => handleChange(item)"
        :class="[
          'ca-select__options__item',
          {
            'ca-select__options__item--selected': item === selectedOption,
          },
        ]"
      >
        {{ getName(item) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ca-select {
  background: #eeeeee;
  border-radius: 1.5rem;
  padding: 0.125rem 0.5rem 0.125rem 0.75rem;
  position: relative;

  @media (min-width: 993px) {
    padding: 0;
    background: #fff;
  }

  &__selected-option {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;

    @media (min-width: 993px) {
      font-size: 1rem;
    }
  }

  &__label-container {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }

  &__icon {
    display: none;

    @media (min-width: 993px) {
      display: flex;
      align-self: center;
      font-size: 1.125rem;
    }
  }

  &__label {
    display: none;

    @media (min-width: 993px) {
      display: block;
      color: #000;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
  }

  &__switch-icon {
    align-self: center;
    font-size: 1.25rem;
    transition: 0.5s ease;
    display: flex;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__options {
    cursor: pointer;
    position: absolute;
    z-index: 999;

    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.32),
      0px 4px 6px 0px rgba(33, 37, 41, 0.2);
    min-width: 105px;
    width: max-content;
    padding: 0.5rem;

    color: #000;
    font-size: 1.125rem;
    line-height: 1.5em;
    text-align: left;

    top: calc(100% + 5px);

    @media (min-width: 993px) {
      top: 100%;
      font-size: 18px;
    }

    &__item {
      padding: 0.5rem;

      &:hover {
        background-color: #f2f2f2;
      }

      &--selected {
        font-weight: 700;
      }
    }
  }
}
</style>
