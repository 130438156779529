import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "invoices-list-table" }
const _hoisted_2 = { class: "invoices-list-table__heading-row" }

import { useBreakpoints } from "@/libs/metha-components";
import { Invoice } from "@/types/InvoiceTypes";

import InvoiceListBodyRow from "@/components/molecules/InvoiceListBodyRow.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesListTable',
  props: {
    invoices: {}
  },
  setup(__props: any) {

const { lgAndUp } = useBreakpoints();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_unref(lgAndUp) ? "Referência" : "Mês"), 1),
      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "invoices-list-table__heading-row--only-desktop" }, "Vencimento", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("p", null, "Status", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "Valor", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Fatura", -1))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (invoice, index) => {
      return (_openBlock(), _createBlock(InvoiceListBodyRow, {
        key: `invoices-table-list-item-${index}`,
        invoice: invoice
      }, null, 8, ["invoice"]))
    }), 128))
  ]))
}
}

})