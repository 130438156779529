<script setup lang="ts">
import { ref, watch, type PropType } from "vue";
import { useRouter } from "vue-router";

import { useInvoicesStore } from "@/stores";

import { getMonthNameFromReferenceMonth } from "@/utils/format";

import CaComposedSelect from "@/components/atoms/CaComposedSelect.vue";

const router = useRouter();

const props = defineProps({
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  primaryOptions: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  secondaryOptions: {
    type: Object as PropType<{ [key: number]: string[] }>,
  },
  getValue: {
    type: Function,
    default: (item: number | string) => item,
  },
  getName: {
    type: Function,
    default: (item: number | string) => item,
  },
  currentYear: {
    type: Number,
    required: true,
  },
  currentInvoice: {
    type: String,
    required: true,
  },
});

const invoicesStore = useInvoicesStore();
const value = ref(props.currentInvoice);
const selectedYear = ref(props.currentYear);

function getName(item: string) {
  if (!item || !item.length) return item;

  return getMonthNameFromReferenceMonth(item);
}

function getCompleteReferenceMonthName(reference_month: string) {
  if (!reference_month) return "";

  const [, year] = reference_month.split("/");
  return `${getMonthNameFromReferenceMonth(reference_month)}/${year}`;
}

watch(value, () => {
  const selectedInvoice = invoicesStore.getInvoiceByReferenceMonth(value.value);

  if (selectedInvoice) {
    router.push(`/faturas/${selectedInvoice.id}`);
  }
});
</script>

<template>
  <div class="view-invoice-composed-select">
    <p class="view-invoice-composed-select__title-desktop">
      Fatura referente a
    </p>
    <p class="view-invoice-composed-select__title-mobile">Referente a</p>
    <CaComposedSelect
      :label="getCompleteReferenceMonthName(currentInvoice)"
      :selectedPrimary="selectedYear"
      :primaryOptions="primaryOptions"
      v-model="value"
      :secondaryOptions="secondaryOptions"
      :getName="getName"
    />
  </div>
</template>

<style lang="scss" scoped>
.view-invoice-composed-select {
  background-color: #fff;
  margin: 1rem auto 0;
  border: 1px solid #afafaf;
  border-radius: 1rem;
  padding: 0.75rem 1.75rem;

  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  justify-content: center;

  color: #6c757d;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  width: calc(100% - 2rem);

  @media (min-width: 993px) {
    border: none;
    background-color: transparent;
    box-shadow: none;
    margin: 1.5rem auto;
    padding: 0;
    width: fit-content;
  }

  &__title {
    &-desktop {
      display: none;

      @media (min-width: 993px) {
        display: inline-block;
      }
    }
    &-mobile {
      @media (min-width: 993px) {
        display: none;
      }
    }
  }
}
</style>
