<script setup lang="ts">
import { ref } from "vue";
import { useBreakpoints, MeIcon, MeModal } from "@/libs/metha-components";
import { toastMessage } from "@/libs/toast";
import type { Contract, ContractFile } from "@/types/Contract";
import { formatDate } from "@/utils/format";
import { getDateTime } from "@/utils/dateManipulation";
import { handleError } from "@/utils/handleError";
import {
  getMethasignContractFiles,
  getDocusignContractFile,
} from "@/utils/contract";

import Spinner from "@/components/atoms/CaSpinner.vue";
import { openLink } from "@/utils/fileManipulation";

defineProps<{
  contract: Contract;
}>();

const { mdAndUp } = useBreakpoints();

const opening = ref(false);
const openMethaSignTermsModal = ref(false);

let methasignTerms = {
  adesao: {} as ContractFile,
  comercializacao: {} as ContractFile,
};

async function openMethaTerms(term: "adesao" | "comercializacao") {
  if (opening.value) return;
  opening.value = true;

  try {
    const { url, name } = methasignTerms[term];
    await openLink(url, name, ".installation-contract__modal");
  } catch (error) {
    handleError(error, (err: string) => {
      toastMessage("error", err);
    });
    openMethaSignTermsModal.value = false;
  } finally {
    opening.value = false;
  }
}

function downloadContract(contract: Contract) {
  if (opening.value) return;

  try {
    opening.value = true;

    if (
      ["metha-contracts", "metha sign", "methasign"].includes(contract.service)
    ) {
      let [adesao, comercializacao] = getMethasignContractFiles(contract);
      methasignTerms = { adesao, comercializacao };
      openMethaSignTermsModal.value = true;
    } else {
      const file = getDocusignContractFile(contract);

      openLink(file.url, file.name, ".installation-contract__modal");
    }
  } catch (error) {
    handleError(error, (err: string) => {
      toastMessage("error", err);
    });
    openMethaSignTermsModal.value = false;
  } finally {
    opening.value = false;
  }
}
</script>

<template>
  <div
    :class="[
      'installation-contract',
      { 'installation-contract--loading': opening },
    ]"
    @click="downloadContract(contract)"
  >
    <template v-if="opening">
      <div class="installation-contract__icon">
        <Spinner :size="20" />
      </div>
      <p class="installation-contract__text">Carregando...</p>
    </template>
    <template v-else>
      <p class="installation-contract__text">
        Termos assinados {{ formatDate(contract.agreed_on) }}
        <span v-if="mdAndUp"> às {{ getDateTime(contract.agreed_on) }}</span>
      </p>
      <div class="installation-contract__icon">
        <MeIcon>file-download</MeIcon>
      </div>
    </template>
  </div>
  <MeModal v-model="openMethaSignTermsModal">
    <div class="installation-contract__modal">
      <div
        class="installation-contract__modal__close-button"
        @click="openMethaSignTermsModal = false"
      >
        <MeIcon>x</MeIcon>
      </div>
      <h3 class="installation-contract__modal__text">
        Faça download dos termos assinados no dia
        {{ formatDate(contract.agreed_on) }} às
        {{ getDateTime(contract.agreed_on) }}
      </h3>
      <div>
        <button
          class="installation-contract__modal__button mb-4"
          type="button"
          @click="openMethaTerms('adesao')"
        >
          <MeIcon class="installation-contract__modal__button__icon">
            contract
          </MeIcon>
          <span class="installation-contract__modal__button__title"
            >Termo de Adesão</span
          >
        </button>
        <button
          class="installation-contract__modal__button"
          type="button"
          @click="openMethaTerms('comercializacao')"
        >
          <MeIcon class="installation-contract__modal__button__icon">
            contract
          </MeIcon>
          <span class="installation-contract__modal__button__title">
            Termo de Comercialização
          </span>
        </button>
      </div>
    </div>
  </MeModal>
</template>

<style lang="scss" scoped>
.installation-contract {
  background-color: #e9ecef;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  gap: 4px;

  @media (min-width: 768px) {
    padding: 10px;
    gap: 24px;
  }

  &:hover {
    opacity: 0.8;
  }

  &--loading {
    opacity: 0.7;
  }

  &__text {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: unset;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    font-size: 18px;

    @media (min-width: 768px) {
      width: 20px;
      font-size: 20px;
    }
  }

  &__modal {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding: 24px 24px 36px 24px;
    text-align: center;
    width: 100%;
    max-width: 556px;
    border-radius: 40px;

    &__close-button {
      font-size: 14px;
      background: #e9ecef;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 100%;

      position: absolute;
      top: 15px;
      right: 20px;

      cursor: pointer;
    }

    &__text {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }

    &__button {
      background: #fff;
      border: 1px solid #ced4da;
      border-radius: 1rem;
      padding: 1rem 1.5rem;
      width: 100%;

      display: flex;
      align-items: center;
      text-align: left;

      cursor: pointer;

      color: #000;
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      text-decoration: none;

      &:hover {
        color: #2db699;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &__title {
        text-decoration: underline;
      }

      &__icon {
        font-size: 20px;
        margin-right: 4px;
      }
    }
  }
}
</style>
