<script setup lang="ts">
import { onBeforeMount, ref } from "vue";
import { fillEmptySlotsInConsumptionHistory } from "@/utils/consumptionHistory";
import SingleBarChart from "@/components/molecules/SingleBarChart.vue";

const props = defineProps<{
  labels: Array<number | string>;
  values: Array<number | string>;
  color: string;
}>();

let chartPayload;

const formattedLabels = ref([]);
const formattedValues = ref([]);

onBeforeMount(() => {
  chartPayload = {
    labels: props.labels,
    values: props.values ?? [],
  };

  if (formattedLabels.value.length > 0 && formattedLabels.value.length < 6) {
    chartPayload = fillEmptySlotsInConsumptionHistory({
      labels: formattedLabels.value.length,
    });
  }

  formattedLabels.value = chartPayload.labels;
  formattedValues.value = chartPayload.values;
});
</script>

<template>
  <div class="past-expenses-graph">
    <h3 class="past-expenses-graph__title">Despesas em R$ dos últimos meses</h3>
    <SingleBarChart
      :labels="formattedLabels"
      title="VALOR FATURAS METHA"
      :values="formattedValues"
      :color="color"
      legendAlignment="left"
    />
  </div>
</template>

<style lang="scss" scoped>
.past-expenses-graph {
  width: 100%;

  &__title {
    font-weight: 500;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
</style>
