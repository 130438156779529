export function getDateTime(value: Date | number) {
  const date = new Date(value);

  let hours: string | number, minutes: string | number;

  hours = date.getHours();
  hours = hours < 10 ? `0${hours}` : hours;

  minutes = date.getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
}
