import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoice-total-and-discount-values" }
const _hoisted_2 = { class: "invoice-total-and-discount-values__discount" }
const _hoisted_3 = { class: "invoice-total-and-discount-values__discount__value" }
const _hoisted_4 = { class: "invoice-total-and-discount-values__total" }
const _hoisted_5 = { class: "invoice-total-and-discount-values__total__value" }

import { formatMoney } from "@/utils/format";
import { Invoice } from "@/types/InvoiceTypes";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceTotalAndDiscountValues',
  props: {
    invoice: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createTextVNode(" Desconto aplicado ")),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(formatMoney)(_ctx.invoice.total_discount)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _cache[1] || (_cache[1] = _createTextVNode(" Total a pagar ")),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(formatMoney)(_ctx.invoice.total_value)), 1)
    ])
  ]))
}
}

})