import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
  getInvoiceStatus,
  getInvoiceStatusColor,
  dangerStatuses,
} from "@/utils/status";

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceViewStatusTag',
  props: {
    status: {}
  },
  setup(__props: any) {

const props = __props;

const statusTranslated = computed(() => {
  if (!props.status) return "";

  return getInvoiceStatus(props.status).toLowerCase();
});

const statusColor = computed(() => getInvoiceStatusColor(props.status));

const statusTextColor = computed(() => {
  if (props.status && dangerStatuses.includes(props.status.toLowerCase())) {
    return "#fff";
  }

  return "#000";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      color: statusTextColor.value,
      'background-color': `rgba(${statusColor.value})`,
    }),
    class: "status-tag"
  }, " fatura " + _toDisplayString(statusTranslated.value), 5))
}
}

})