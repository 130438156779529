import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid-faq" }
const _hoisted_2 = { class: "grid-faq__container" }
const _hoisted_3 = { class: "grid-faq__hidden-container" }
const _hoisted_4 = {
  key: 0,
  class: "grid-faq__expander"
}

import { computed, onBeforeMount, ref } from "vue";
import { toastMessage } from "@/libs/toast";
import { MeButton, MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores/user";
import { useFaqStore } from "@/stores/faq";

import { handleError } from "@/utils/handleError";
import FaqSectionCard from "@/components/molecules/FaqSectionCard";

const maxRows = 1;
const columns = 3;


export default /*@__PURE__*/_defineComponent({
  __name: 'GridFaq',
  setup(__props) {

const userStore = useUserStore();
const faqStore = useFaqStore();

const isOpen = ref(false);

const maxDisplayedElements = computed(() => {
  return maxRows * columns;
});

const hasExpandButton = computed(() => {
  return faqStore.sections.length > maxRows * columns;
});

const alwaysDisplayedElements = computed(() => {
  return faqStore.sections.slice(0, maxDisplayedElements.value);
});

const hiddenElements = computed(() => {
  if (faqStore.sections.length > maxDisplayedElements.value) {
    return faqStore.sections.slice(maxDisplayedElements.value);
  }

  return [];
});

onBeforeMount(async () => {
  try {
    userStore.pageLoading = true;

    await faqStore.getHelpCenterCategory();

    const categoryId = faqStore.categoryId ?? 0;
    await faqStore.getSections(categoryId);
  } catch (error) {
    handleError(error, (err: string) => {
      toastMessage("error", err);
    });
  } finally {
    userStore.pageLoading = false;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(alwaysDisplayedElements.value, (section, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `faq-section-card-${index}`
        }, [
          _createVNode(_unref(FaqSectionCard), {
            section: section,
            maxArticles: 4
          }, null, 8, ["section"])
        ]))
      }), 128))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hiddenElements.value, (section, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `faq-section-card-hidden-${index}`
        }, [
          _createVNode(_unref(FaqSectionCard), { section: section }, null, 8, ["section"])
        ]))
      }), 128))
    ], 512), [
      [_vShow, hasExpandButton.value && isOpen.value]
    ]),
    (hasExpandButton.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_unref(MeButton), {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value)),
            class: "grid-faq__container__expander__button",
            variant: "outline",
            color: "foreground"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(MeIcon), {
                style: _normalizeStyle({ transform: `rotate(${isOpen.value ? '180' : '0'}deg)` })
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" chevron-bottom ")
                ])),
                _: 1
              }, 8, ["style"]),
              _createElementVNode("p", null, "Ver " + _toDisplayString(isOpen.value ? "menos" : "mais") + " tópicos", 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})