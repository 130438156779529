<script setup lang="ts">
import { useRouter } from "vue-router";
import { MeIcon } from "@/libs/metha-components";

const props = defineProps({
  icon: String,
  title: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    required: true,
  },
  active: Boolean,
});

const router = useRouter();

function redirect() {
  if (props.active) return;
  router.push(props.route);
}
</script>

<template>
  <div @click="redirect" class="mobile-nav-item">
    <div class="mobile-nav-item__icon">
      <MeIcon>{{ icon }}</MeIcon>
    </div>
    <p class="mobile-nav-item__title">{{ title }}</p>
  </div>
</template>

<style lang="scss" scoped>
.mobile-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  --mobile-nav-item-background-color: #e3e3e3;

  &:hover {
    --mobile-nav-item-background-color: #cacaca;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;
    background-color: var(--mobile-nav-item-background-color);
    border-radius: 100%;

    font-size: 1.5rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    text-align: center;
  }
}
</style>
