import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-nav-item__icon" }
const _hoisted_2 = { class: "mobile-nav-item__title" }

import { useRouter } from "vue-router";
import { MeIcon } from "@/libs/metha-components";


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileNavigationItem',
  props: {
  icon: String,
  title: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    required: true,
  },
  active: Boolean,
},
  setup(__props) {

const props = __props;

const router = useRouter();

function redirect() {
  if (props.active) return;
  router.push(props.route);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onClick: redirect,
    class: "mobile-nav-item"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(MeIcon), null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(__props.icon), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.title), 1)
  ]))
}
}

})