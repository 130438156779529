<script setup lang="ts">
import { computed } from "vue";
import { MeIcon } from "@/libs/metha-components";

import {
  getSustainabilityCardText,
  getSustainabilityCardValue,
} from "@/utils/sustainabilityCard";

const props = defineProps({
  cardInfo: {
    type: Object,
    required: true,
  },
  kwhConsumption: {
    type: Number,
    required: true,
  },
  activeIndex: {
    type: Number,
    required: true,
  },
  numberOfBullets: {
    type: Number,
    required: true,
  },
  goToNextCard: {
    type: Function,
    required: true,
  },
});

const cardValue = computed(() => {
  return getSustainabilityCardValue(props.cardInfo.type, props.kwhConsumption);
});
</script>

<template>
  <div class="sustainability-card">
    <div class="sustainability-card__header">
      <h2 class="sustainability-card__header__title">Sustentabilidade</h2>
      <div class="sustainability-card__header__icon">
        <MeIcon>nm-plant</MeIcon>
      </div>
    </div>
    <p class="sustainability-card__text">
      {{ getSustainabilityCardText(cardInfo.type) }}
    </p>
    <p
      :class="[
        'sustainability-card__value',
        {
          'sustainability-card__value--sub-margin-remove':
            cardValue.match('<sub>'),
        },
      ]"
      v-html="cardValue"
    ></p>
    <div class="sustainability-card__nav-indicator" @click="goToNextCard">
      <div
        v-for="idx in numberOfBullets"
        :key="`sustainability-card__nav-indicator__bullet-${idx}`"
        :class="[
          'sustainability-card__nav-indicator__bullet',
          {
            'sustainability-card__nav-indicator__bullet--active':
              activeIndex + 1 === idx,
          },
        ]"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sustainability-card {
  position: relative;
  background-color: #a8df53;
  color: #000;
  padding: 1.75rem 2rem 1.5rem;
  min-height: 190px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: normal;

  border-radius: 2.5rem;

  @media (min-width: 339px) {
    min-height: 175px;
  }

  @media (min-width: 993px) {
    border-radius: 1rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    &__title {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.3125rem;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 1.25rem;
    }
  }

  &__text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;

    margin: 1.125rem 0 0.25rem;
  }

  &__value {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.5rem;

    @media (min-width: 993px) and (max-width: 1180px) {
      font-size: 1.5rem;
    }

    sub {
      font-size: 1rem;
    }

    &--sub-margin-remove {
      margin-bottom: -13px;
    }
  }

  &__nav-indicator {
    position: absolute;
    bottom: 1.25rem;
    left: 0;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;

    &__bullet {
      background-color: transparent;
      border: 1px solid #232326;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      height: 8px;
      width: 8px;

      &--active {
        background-color: #232326;
      }
    }
  }
}
</style>
