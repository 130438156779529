import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { MeButton } from "@/libs/metha-components";
import { Invoice } from "@/types/InvoiceTypes";
import { openLink } from "@/utils/fileManipulation";


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadFileButton',
  props: {
    invoice: {},
    disabled: { type: Boolean },
    url: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.url)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(MeButton), {
          onClick: _cache[0] || (_cache[0] = 
        () =>
          _unref(openLink)(
            _ctx.url!,
            `Fatura Metha ${_ctx.invoice.reference_month} - ${_ctx.invoice.installation_number}`
          )
      ),
          block: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        })
      ]))
    : _createCommentVNode("", true)
}
}

})