<script setup lang="ts">
import { useRouter } from "vue-router";

import {
  MeIcon,
  // MeIconNotification,
  useBreakpoints,
} from "@/libs/metha-components";

import { useUserStore } from "@/stores";
import { capitalize } from "@/utils/format";

import InstallationsNavSelect from "@/components/molecules/InstallationsNavSelect.vue";
import MobileActionButton from "@/components/molecules/MobileActionButton.vue";

defineProps<{
  title: string;
  notificationNumber: number;
  hideInstallationSwitchComponent?: boolean;
}>();

const router = useRouter();
const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

async function logOut() {
  await userStore.logOutUser();
  router.push("/login");
}
</script>

<template>
  <div class="me-header">
    <div class="me-header__container me-header__information">
      <div class="me-header__wrapper-menu-title">
        <template v-if="!lgAndUp">
          <MobileActionButton />
        </template>
        <h1 class="me-header__greeting">{{ capitalize(title) }}</h1>
      </div>
      <div class="me-header__options">
        <div class="me-header__options__item">
          <!-- <MeIconNotification
              :size="26"
              icon="bell"
              :notificationNumber="notificationNumber"
            />
            <p>Alertas</p> -->
        </div>
        <div class="me-header__options__item" @click="logOut">
          <span class="me-header__options__item__icon">
            <MeIcon>nm-logout</MeIcon>
          </span>
          <p>Sair</p>
        </div>
      </div>
    </div>
    <div v-if="!hideInstallationSwitchComponent" class="me-header__container">
      <div
        v-if="!userStore.pageLoading || !lgAndUp"
        class="me-header__installation-select"
      >
        <InstallationsNavSelect />
        <p class="me-header__installation-select__address">
          {{ capitalize(userStore.selectedInstallation.address) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.me-header {
  width: 100%;
  max-width: 100vw;
  background-color: transparent;

  &__container {
    width: calc(100% - 2rem);
    margin: 0 auto;

    @media (min-width: 1020px) {
      max-width: 1180px;
      width: calc(100% - 6rem);
    }
  }

  &__information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 2.125rem 0 16px;

    @media (min-width: 993px) {
      gap: 1.5rem;
      padding: 3rem 1.5rem 24px;
    }
  }

  &__installation-select {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding-bottom: 8px;

    @media (min-width: 993px) {
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      background-color: #fff;
      border-radius: 1rem;
      padding: 1rem 1.5rem;
      gap: 1rem;
    }

    &__address {
      display: none;

      @media (min-width: 993px) {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
        text-transform: capitalize;
      }
    }
  }

  &__wrapper-menu-title {
    display: flex;
    align-items: flex-end;
    gap: 24px;
  }

  &__greeting {
    color: #000;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;

    @media (min-width: 993px) {
      font-size: 2.125rem;
      line-height: 2.25rem;
    }
  }

  &__options {
    display: none;

    @media (min-width: 993px) {
      display: flex;
      align-items: flex-start;
      gap: 24px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 4px;

      color: #000;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.5rem;

      cursor: pointer;

      &__icon {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
