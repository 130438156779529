<script lang="ts" setup>
import { computed, markRaw, reactive, ref } from "vue";
import SustainabilityCard from "@/components/atoms/SustainabilityCard";

const props = defineProps({
  kwhConsumption: {
    type: Number,
    required: true,
  },
  active: Boolean,
});

const cardsWithTypes = markRaw([
  {
    type: "co2",
  },
  {
    type: "trees",
  },
  {
    type: "coal",
  },
]);

const animating = ref(false);
const cardsCounter = ref(0);
const decisionVal = 40;
const pullDeltaX = ref(0);
const deg = ref(0);

const refs = ref<Array<HTMLDivElement | Element | null>>([]);

const cards = reactive(
  cardsWithTypes.map((el, index) => ({
    ...el,
    toRight: false,
    toLeft: false,
    inactive: false,
    pulling: false,
    transform: "",
    zIndex: cardsWithTypes.length * 2 - index,
  }))
);

function resetAllCards() {
  cardsCounter.value = 0;
  cards.forEach((card, index) => {
    cards[index] = {
      ...card,
      toRight: false,
      toLeft: false,
      inactive: false,
      pulling: false,
      transform: "",
      zIndex: cardsWithTypes.length - index,
    };
  });
}

const card = computed(() => {
  return cards[cardsCounter.value];
});

function goToNextCard() {
  pullChange(-200);
  release();
}

function pullChange(customPullValue?: number) {
  if (typeof customPullValue === "number") {
    pullDeltaX.value = customPullValue;
  }

  animating.value = true;
  deg.value = pullDeltaX.value / 10;
  card.value.transform =
    "translateX(" + pullDeltaX.value + "px) rotate(" + deg.value + "deg)";
}

function release() {
  card.value.pulling = false;
  const pullValue = pullDeltaX.value;
  let goToNextCard = false;
  let reset = false;

  if (pullValue >= decisionVal) {
    card.value.toRight = true;
  } else if (pullValue <= -decisionVal) {
    card.value.toLeft = true;
  }

  if (Math.abs(pullValue) >= decisionVal) {
    card.value.inactive = true;

    setTimeout(function () {
      card.value.zIndex -= cards.length;

      card.value.inactive = false;
      card.value.toLeft = false;
      card.value.toRight = false;
      card.value.transform = "";

      goToNextCard = true;

      if (cardsCounter.value >= cards.length - 1) {
        reset = true;
        resetAllCards();
      }
    }, 300);
  }

  setTimeout(function () {
    card.value.transform = "";

    if (goToNextCard) {
      cardsCounter.value += 1;
    }

    if (reset) {
      cardsCounter.value = 0;
    }

    pullDeltaX.value = 0;
    animating.value = false;
  }, 300);
}

function startInteraction(e) {
  if (animating.value || !props.active) return;

  var startX = e.pageX || e.touches[0].pageX;

  card.value.pulling = true;

  document.addEventListener("mousemove", moveInteraction);
  document.addEventListener("touchmove", moveInteraction);
  document.addEventListener("mouseup", endInteraction);
  document.addEventListener("touchend", endInteraction);

  function moveInteraction(e) {
    var x = e.pageX || e.touches[0].pageX;
    pullDeltaX.value = x - startX;
    if (!pullDeltaX.value) return;
    pullChange();
  }

  function endInteraction() {
    document.removeEventListener("mousemove", moveInteraction);
    document.removeEventListener("touchmove", moveInteraction);
    document.removeEventListener("mouseup", endInteraction);
    document.removeEventListener("touchend", endInteraction);
    if (!pullDeltaX.value) return;
    release();
  }
}
</script>

<template>
  <div class="swipe-sustainability-statistics">
    <div
      v-for="(card, index) in cards"
      :key="index"
      :class="[
        'swipe-sustainability-statistics__card',
        {
          'swipe-sustainability-statistics--to-right': card.toRight,
          'swipe-sustainability-statistics--to-left': card.toLeft,
          'swipe-sustainability-statistics--pulling': card.pulling,
          'swipe-sustainability-statistics--inactive': card.inactive,
        },
      ]"
      :style="`transform: ${card.transform}; z-index: ${card.zIndex};`"
      @mousedown="startInteraction"
      @touchstart="startInteraction"
      :ref="
        (el) => {
          refs[index] = el;
        }
      "
    >
      <SustainabilityCard
        :cardInfo="card"
        :kwhConsumption="kwhConsumption"
        :activeIndex="index"
        :numberOfBullets="cards.length"
        :goToNextCard="goToNextCard"
      />
    </div>
  </div>
</template>

<style lang="scss">
.swipe-sustainability-statistics {
  position: relative;
  width: 100%;
  height: 100%;

  &__card {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 1;
    border-radius: 2.5rem;

    @media (min-width: 993px) {
      border-radius: 1rem;
    }
  }

  &--inactive {
    pointer-events: none;
    opacity: 0.3;
  }

  &--pulling {
    opacity: 0.8;
  }

  &--to-left {
    transform: translateX(-400%) rotate(-20deg);
  }

  &--to-right {
    transform: translateX(400%) rotate(20deg);
  }

  &--below {
    z-index: -1;
  }

  &--reset {
    transition: transform 0.3s;
    transform: translateX(0) !important;
  }
}
</style>
