import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { useBreakpoints } from "@/libs/metha-components";

import SwiperContainer from "@/components/molecules/SwiperContainer.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileSwiper',
  setup(__props) {

const { lgAndUp } = useBreakpoints();

return (_ctx: any,_cache: any) => {
  return (!_unref(lgAndUp))
    ? (_openBlock(), _createBlock(SwiperContainer, { key: 0 }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }))
    : _renderSlot(_ctx.$slots, "default", { key: 1 })
}
}

})