<script lang="ts" setup>
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";

import RecaptchaWrapper from "@/components/organisms/RecaptchaWrapper.vue";
import LoadingScreen from "@/components/organisms/LoadingScreen.vue";

import {
  useUserStore,
  usePersistentAuthStore,
  useWarningStore,
} from "@/stores";
import {
  setAppStarted,
  emitNotification,
  listenNotificationFromApp,
} from "./utils/appComunication";

const router = useRouter();
const userStore = useUserStore();
const persistentAuthStore = usePersistentAuthStore();
const warningStore = useWarningStore();

onBeforeMount(() => {
  userStore.loadingScreen = false;

  listenNotificationFromApp("appStarted", () => {
    setAppStarted(true);
  });

  const stores = {
    "@metha-energia/persist-session": persistentAuthStore,
    "@metha-energia/adc-warnings": warningStore,
  };

  const storesNames = Object.keys(stores);

  listenNotificationFromApp(
    "recoverStoreFromAndroid",
    (store: { key: string; content: string }) => {
      const data = JSON.parse(store.content);

      if (storesNames.includes(store.key)) {
        const key = store.key as keyof typeof stores;
        stores[key].getStoreFromMobile(data);
      }
    }
  );

  emitNotification("websiteIsLoaded");

  listenNotificationFromApp("redirectToPage", (pathToRedirect: string) => {
    router.push(pathToRedirect);
  });

  storesNames.forEach((storeName) => {
    const key = storeName as keyof typeof stores;
    stores[key].$subscribe((_, state) => {
      emitNotification("updateStore", {
        key: storeName,
        content: JSON.stringify(state),
      });
    });
  });
});
</script>

<template>
  <recaptcha-wrapper>
    <component :is="$route.meta.layout || 'div'">
      <router-view />
    </component>
  </recaptcha-wrapper>
  <LoadingScreen v-if="userStore.loadingScreen" />
</template>

<style lang="scss">
body,
html {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  position: relative;
}
</style>
