import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "installation-contract__icon" }
const _hoisted_2 = { class: "installation-contract__text" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "installation-contract__icon" }
const _hoisted_5 = { class: "installation-contract__modal" }
const _hoisted_6 = { class: "installation-contract__modal__text" }

import { ref } from "vue";
import { useBreakpoints, MeIcon, MeModal } from "@/libs/metha-components";
import { toastMessage } from "@/libs/toast";
import type { Contract, ContractFile } from "@/types/Contract";
import { formatDate } from "@/utils/format";
import { getDateTime } from "@/utils/dateManipulation";
import { handleError } from "@/utils/handleError";
import {
  getMethasignContractFiles,
  getDocusignContractFile,
} from "@/utils/contract";

import Spinner from "@/components/atoms/CaSpinner.vue";
import { openLink } from "@/utils/fileManipulation";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationContract',
  props: {
    contract: {}
  },
  setup(__props: any) {



const { mdAndUp } = useBreakpoints();

const opening = ref(false);
const openMethaSignTermsModal = ref(false);

let methasignTerms = {
  adesao: {} as ContractFile,
  comercializacao: {} as ContractFile,
};

async function openMethaTerms(term: "adesao" | "comercializacao") {
  if (opening.value) return;
  opening.value = true;

  try {
    const { url, name } = methasignTerms[term];
    await openLink(url, name, ".installation-contract__modal");
  } catch (error) {
    handleError(error, (err: string) => {
      toastMessage("error", err);
    });
    openMethaSignTermsModal.value = false;
  } finally {
    opening.value = false;
  }
}

function downloadContract(contract: Contract) {
  if (opening.value) return;

  try {
    opening.value = true;

    if (
      ["metha-contracts", "metha sign", "methasign"].includes(contract.service)
    ) {
      let [adesao, comercializacao] = getMethasignContractFiles(contract);
      methasignTerms = { adesao, comercializacao };
      openMethaSignTermsModal.value = true;
    } else {
      const file = getDocusignContractFile(contract);

      openLink(file.url, file.name, ".installation-contract__modal");
    }
  } catch (error) {
    handleError(error, (err: string) => {
      toastMessage("error", err);
    });
    openMethaSignTermsModal.value = false;
  } finally {
    opening.value = false;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass([
      'installation-contract',
      { 'installation-contract--loading': opening.value },
    ]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (downloadContract(_ctx.contract)))
    }, [
      (opening.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(Spinner, { size: 20 })
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "installation-contract__text" }, "Carregando...", -1))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("p", _hoisted_2, [
              _createTextVNode(" Termos assinados " + _toDisplayString(_unref(formatDate)(_ctx.contract.agreed_on)) + " ", 1),
              (_unref(mdAndUp))
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, " às " + _toDisplayString(_unref(getDateTime)(_ctx.contract.agreed_on)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("file-download")
                ])),
                _: 1
              })
            ])
          ], 64))
    ], 2),
    _createVNode(_unref(MeModal), {
      modelValue: openMethaSignTermsModal.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((openMethaSignTermsModal).value = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "installation-contract__modal__close-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (openMethaSignTermsModal.value = false))
          }, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("x")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("h3", _hoisted_6, " Faça download dos termos assinados no dia " + _toDisplayString(_unref(formatDate)(_ctx.contract.agreed_on)) + " às " + _toDisplayString(_unref(getDateTime)(_ctx.contract.agreed_on)), 1),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "installation-contract__modal__button mb-4",
              type: "button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (openMethaTerms('adesao')))
            }, [
              _createVNode(_unref(MeIcon), { class: "installation-contract__modal__button__icon" }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" contract ")
                ])),
                _: 1
              }),
              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "installation-contract__modal__button__title" }, "Termo de Adesão", -1))
            ]),
            _createElementVNode("button", {
              class: "installation-contract__modal__button",
              type: "button",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (openMethaTerms('comercializacao')))
            }, [
              _createVNode(_unref(MeIcon), { class: "installation-contract__modal__button__icon" }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" contract ")
                ])),
                _: 1
              }),
              _cache[11] || (_cache[11] = _createElementVNode("span", { class: "installation-contract__modal__button__title" }, " Termo de Comercialização ", -1))
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})