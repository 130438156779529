import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "past-injections-graph" }

import { onBeforeMount, ref, computed } from "vue";
import { fillEmptySlotsInConsumptionHistory } from "@/utils/consumptionHistory";
import StackedBarChart from "@/components/molecules/StackedBarChart.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PastConsumptionGraph',
  props: {
    labels: {},
    primaryColor: {},
    primaryValues: {},
    secondaryColor: {},
    secondaryValues: {}
  },
  setup(__props: any) {

const props = __props;

let chartPayload;

const labels = ref([]);
const methaValues = ref([]);
const supplierValues = ref([]);

const methaColor = computed(() => {
  return props.primaryColor ?? "#FFC300";
});

const supplierColor = computed(() => {
  return props.secondaryColor ?? "#E9E9E9";
});

onBeforeMount(() => {
  chartPayload = {
    labels: props.labels,
    methaConsumptionValues: props.primaryValues ?? [],
  };

  if (props.secondaryValues?.length > 0) {
    chartPayload = {
      ...chartPayload,
      supplierConsumptionValues: props.secondaryValues ?? [],
    };
  }

  if (chartPayload.labels.length > 0 && chartPayload.labels.length < 6) {
    chartPayload = fillEmptySlotsInConsumptionHistory(chartPayload);
  }

  labels.value = chartPayload.labels;
  methaValues.value = chartPayload.methaConsumptionValues;
  supplierValues.value = chartPayload.supplierConsumptionValues;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "past-injections-graph__title" }, " Consumo em kWh dos últimos meses ", -1)),
    _createVNode(StackedBarChart, {
      labels: labels.value,
      primaryTitle: "ENERGIA LIMPA METHA",
      primaryValues: methaValues.value,
      primaryColor: methaColor.value,
      secondaryTitle: "ENERGIA CONCESSIONÁRIA",
      secondaryValues: supplierValues.value,
      secondaryColor: supplierColor.value
    }, null, 8, ["labels", "primaryValues", "primaryColor", "secondaryValues", "secondaryColor"])
  ]))
}
}

})