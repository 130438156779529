<script setup lang="ts">
import { computed } from "vue";
import {
  getInvoiceStatus,
  getInstallationStatus,
  getInvoiceStatusColor,
  getInstallationStatusColor,
} from "@/utils/status";

const props = defineProps<{
  status: string;
  invoice?: boolean;
  block?: boolean;
  definedText?: string;
}>();

const hasDefinedText = computed(() => {
  return (props.definedText?.length ?? 0) > 0;
});

const statusTranslated = computed(() => {
  let translatedStatus = "";

  if (props.invoice) {
    translatedStatus = getInvoiceStatus(props.status);
  } else {
    translatedStatus = getInstallationStatus(props.status);
  }

  return translatedStatus;
});

const statusColor = computed(() => {
  if (props.invoice) {
    return getInvoiceStatusColor(props.status);
  }

  return getInstallationStatusColor(props.status);
});
</script>

<template>
  <div
    :style="`--bullet-status-tag-status-color: rgb(${statusColor});`"
    class="bullet-status-tag"
  >
    <span
      :style="{ 'background-color': `rgb(${statusColor})` }"
      class="bullet-status-tag__ball"
    ></span>
    <!-- <span class="bullet-status-tag__ball">&#9679;</span> -->
    <span>
      {{ hasDefinedText ? definedText : statusTranslated }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.bullet-status-tag {
  width: fit-content;
  height: min-content;

  display: flex;
  align-items: center;
  gap: 0.44rem;

  color: var(--bullet-status-tag-status-color);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;

  &__ball {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: var(--bullet-status-tag-status-color);
  }

  &--block {
    width: 100%;
  }
}
</style>
