<script setup lang="ts">
import BarcodeGenerator from "vue3-barcode";
import { useBreakpoints, MeIcon } from "@/libs/metha-components";

import CopyContainer from "@/components/atoms/CopyContainer.vue";

defineProps<{
  barcode: string;
}>();

const { mdAndUp } = useBreakpoints();
</script>

<template>
  <div class="barcode-payment__copy">
    <CopyContainer
      :valueToCopy="barcode"
      :disabled="!barcode"
      successMessage="Código de barras copiado!"
    >
      <div
        :class="[
          'barcode-payment__line',
          { 'barcode-payment__line--disabled': !barcode },
        ]"
      >
        <p v-if="!mdAndUp && barcode" class="barcode-payment__line__icon">
          <MeIcon>reader-barcode</MeIcon>
        </p>
        <p class="barcode-payment__line__value">
          {{
            barcode
              ? barcode
              : "O pagamento por código de barras não está disponível para esta fatura."
          }}
        </p>
      </div>
    </CopyContainer>
  </div>
  <p class="barcode-payment__readable">
    <BarcodeGenerator
      v-if="barcode"
      :value="barcode.replace(' ', '')"
      :height="77"
      :width="1"
      :displayValue="false"
    />
  </p>
</template>

<style lang="scss" scoped>
.barcode-payment {
  width: 100%;
  max-width: 675px !important;
  margin: 0 auto;

  &__copy {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  &__title {
    color: #6c757d;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid #dee2e6;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    width: 100%;

    padding: 10px 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    @media (min-width: 993px) {
      padding: 12px 20px;
      font-size: 16px;
      line-height: 20.83px;
      text-align: center;
    }

    &:not(&--disabled) {
      &:hover {
        background: #dee2e6;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      font-size: 28px;
    }

    &__value {
      width: 200px;
      max-width: 100%;
      text-align: center;

      @media (min-width: 510px) {
        width: fit-content;
      }
    }

    &--disabled {
      background-color: #f5f5f5;
      color: #4e545a;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: not-allowed;
    }
  }

  &__readable {
    display: none;

    @media (min-width: 993px) {
      display: block;
      width: fit-content;
      margin: 0 auto;
    }
  }
}
</style>
