import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/chevron-left-circled.svg'
import _imports_1 from '@/assets/icons/chevron-right-circled.svg'


const _hoisted_1 = { class: "swiper-container" }
const _hoisted_2 = { class: "glide" }
const _hoisted_3 = {
  class: "glide__track",
  "data-glide-el": "track"
}
const _hoisted_4 = { class: "glide__slides" }

import { onMounted, ref } from "vue";
import Glide from "@glidejs/glide";


export default /*@__PURE__*/_defineComponent({
  __name: 'SwiperContainer',
  setup(__props) {

const glide = ref();
const maxSlides = ref(0);
const currentSlide = ref(0);

const gliderOptions: Partial<Glide.Options> = {
  type: "slider",
  rewind: false,
};

onMounted(() => {
  glide.value = new Glide(".glide", gliderOptions);

  glide.value.on("run", (eventParam: { direction: "<" | ">" }) => {
    currentSlide.value += eventParam.direction === "<" ? -1 : 1;
  });

  glide.value.mount();

  const container = document.querySelector(".glide__slides");

  const children = container?.children ?? [];

  if (children.length > 0) {
    maxSlides.value = children.length;
    for (let i = 0; i < children.length; i += 1) {
      children[i].classList.add(".glide__slide");
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ]),
    (currentSlide.value !== 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "swiper-container__prev-slide",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (glide.value.go('<')))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Voltar",
            title: "Voltar"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (currentSlide.value !== maxSlides.value - 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "swiper-container__next-slide",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (glide.value.go('>')))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "Próximo",
            title: "Próximo"
          }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ]))
}
}

})