import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-installation-info" }
const _hoisted_2 = { class: "user-installation-info__basic" }
const _hoisted_3 = { class: "user-installation-info__basic__name" }
const _hoisted_4 = { class: "user-installation-info__advanced" }

import { useUserStore } from "@/stores";
import { capitalize } from "@/utils/format";

import { Invoice } from "@/types/InvoiceTypes";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAndInstallationInfo',
  props: {
    invoice: {}
  },
  setup(__props: any) {

const userStore = useUserStore();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(capitalize)(_unref(userStore).name)), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(capitalize)(_unref(userStore).selectedInstallation.address)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, " N° Instalação: " + _toDisplayString(_ctx.invoice.installation_number), 1),
      _createElementVNode("p", null, "N° Cliente: " + _toDisplayString(_ctx.invoice.client_number), 1),
      _createElementVNode("p", null, _toDisplayString(_unref(capitalize)(
            [
              _ctx.invoice.subclass,
              _ctx.invoice.tax_modality,
              _ctx.invoice.connection_type,
            ].join(" ")
          )), 1)
    ])
  ]))
}
}

})