import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "consumption-graph" }
const _hoisted_2 = { class: "consumption-graph__graph" }

import { MeDonutChart } from "@/libs/metha-components";

import ChartLegend from "@/components/atoms/ChartLegend.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ConsumptionGraph',
  props: {
    total: {},
    active: {},
    discount: {},
    mainColor: {},
    secondaryColor: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(MeDonutChart), {
        totalConsumption: _ctx.total,
        "metha-consumption": _ctx.active,
        "supplier-consumption": _ctx.total - _ctx.active
      }, null, 8, ["totalConsumption", "metha-consumption", "supplier-consumption"])
    ]),
    _createVNode(ChartLegend, {
      items: [
        {
          kwh: _ctx.active,
          supplier: 'Metha Energia',
          hasDiscount: true,
          discount: _ctx.discount * 100,
          color: '#ffc300',
        },
        {
          kwh: _ctx.total - _ctx.active,
          supplier: 'Concessionária',
          color: '#E3E5E4',
        },
      ]
    }, null, 8, ["items"])
  ]))
}
}

})