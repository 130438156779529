import { AxiosRequestConfig } from "axios";
import request from "./request";
import { Query } from "./types";

export async function getInstallations(
  userId: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "GET",
    url: `/client-area/get-installation/${userId}`,
    params,
    ...config,
  });
}

export async function getConsumptionHistory(
  installationNumber: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "GET",
    url: `/client-area/get-installation/${installationNumber}/consumption-history`,
    params,
    ...config,
  });
}
