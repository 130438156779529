<script setup lang="ts">
import { ref, computed, readonly, onBeforeMount } from "vue";

import { MeIcon, useBreakpoints } from "@/libs/metha-components";

import { Invoice } from "@/types/InvoiceTypes";
import InvoicesListTable from "@/components/molecules/InvoicesListTable.vue";
import CaSelect from "@/components/atoms/CaSelect.vue";
import { orderInvoices } from "@/utils/orderInvoices";

const props = defineProps<{
  invoices: Invoice[];
}>();

const { lgAndUp } = useBreakpoints();
const year = ref<number | string>("Últimas 12 faturas");
const yearOptions = ref<Array<number | string>>(["Últimas 12 faturas"]);

const ordenationParameter = ref("reference_month");
const orderOptions = readonly([
  {
    name: "Mês Referência",
    value: "reference_month",
  },
  {
    name: "Vencimento",
    value: "due_date",
  },
  {
    name: "Status",
    value: "status",
  },
  {
    name: "Valor",
    value: "total_value",
  },
]);

const orderedInvoices = computed(() => {
  let filteredInvoices = props.invoices;
  let hasYearFilter = typeof year.value === "number";

  if (hasYearFilter) {
    filteredInvoices = props.invoices.filter(({ reference_month }) => {
      const [, invoiceYear] = reference_month.split("/");

      return +invoiceYear === year.value;
    });
  }

  filteredInvoices = orderInvoices(filteredInvoices, ordenationParameter.value);

  return hasYearFilter ? filteredInvoices : filteredInvoices.slice(0, 12);
});

function getNameForCaSelectYear(item: number) {
  return item;
}

function getValueForCaSelectYear(item: number) {
  return item;
}

onBeforeMount(() => {
  for (let i = 0; i < props.invoices.length; i += 1) {
    const { reference_month } = props.invoices[i];
    const [, invoiceYear] = reference_month.split("/");

    yearOptions.value = [...new Set([...yearOptions.value, +invoiceYear])];
  }

  yearOptions.value.sort((a, b) => {
    if (typeof a === "string") return -1;
    if (typeof b === "string") return 1;

    return b - a;
  });
});
</script>

<template>
  <section class="adc__invoices__section invoices-list">
    <h2 class="invoices-list__title">Lista de Faturas</h2>
    <div class="invoices-list__filters">
      <p class="invoices-list__filters__select-year">
        <span>
          <CaSelect
            label="Filtrar"
            v-model="year"
            :options="yearOptions"
            :optionsSide="lgAndUp ? 'right' : 'left'"
            :getName="getNameForCaSelectYear"
            :getValue="getValueForCaSelectYear"
          />
        </span>
      </p>
      <p class="invoices-list__filters__select-order">
        <span>
          <CaSelect
            label="Ordenar"
            v-model="ordenationParameter"
            :options="orderOptions"
            optionsSide="right"
          />
        </span>
      </p>
    </div>
    <InvoicesListTable :invoices="orderedInvoices" />
    <p class="invoices-list__info">
      <span class="invoices-list__info__icon">
        <MeIcon>alert-circle</MeIcon>
      </span>
      <span class="invoices-list__info__text">
        As faturas negociadas ou em negociação não constam nesta lista.
      </span>
    </p>
  </section>
</template>

<style lang="scss" scoped>
.invoices-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__title {
    display: none;

    @media (min-width: 993px) {
      display: block;
      color: #7a7a7a;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    @media (min-width: 993px) {
      gap: 0.5rem;
      justify-content: space-between;
    }

    &__select-order,
    &__select-year {
      display: flex;
      align-items: center;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-top: 1rem;

    @media (min-width: 993px) {
      margin-top: 1.5rem;
    }

    &__text {
      flex-grow: 1;

      color: #7a7a7a;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;

      @media (min-width: 993px) {
        font-size: 1rem;
      }
    }

    &__icon {
      flex-grow: 0;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1.125rem;
      color: #6c757d;
    }
  }
}
</style>

<style>
.me-select__list {
  background: #fff !important;
}
</style>
