import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "past-expenses-graph" }

import { onBeforeMount, ref } from "vue";
import { fillEmptySlotsInConsumptionHistory } from "@/utils/consumptionHistory";
import SingleBarChart from "@/components/molecules/SingleBarChart.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PastExpensesGraph',
  props: {
    labels: {},
    values: {},
    color: {}
  },
  setup(__props: any) {

const props = __props;

let chartPayload;

const formattedLabels = ref([]);
const formattedValues = ref([]);

onBeforeMount(() => {
  chartPayload = {
    labels: props.labels,
    values: props.values ?? [],
  };

  if (formattedLabels.value.length > 0 && formattedLabels.value.length < 6) {
    chartPayload = fillEmptySlotsInConsumptionHistory({
      labels: formattedLabels.value.length,
    });
  }

  formattedLabels.value = chartPayload.labels;
  formattedValues.value = chartPayload.values;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "past-expenses-graph__title" }, "Despesas em R$ dos últimos meses", -1)),
    _createVNode(SingleBarChart, {
      labels: formattedLabels.value,
      title: "VALOR FATURAS METHA",
      values: formattedValues.value,
      color: _ctx.color,
      legendAlignment: "left"
    }, null, 8, ["labels", "values", "color"])
  ]))
}
}

})