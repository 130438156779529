import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoice-measurements-date" }
const _hoisted_2 = { class: "invoice-measurements-date__values" }
const _hoisted_3 = { class: "invoice-measurements-date__dates-container" }
const _hoisted_4 = { class: "invoice-measurements-date__dates" }

import { Invoice } from "@/types/InvoiceTypes";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceMeasurementsDate',
  props: {
    invoice: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, " Leitura Anterior: " + _toDisplayString(_ctx.invoice.meter?.last_reading_value), 1),
      _createElementVNode("p", null, " Leitura Atual: " + _toDisplayString(_ctx.invoice.meter?.current_reading_value), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[0] || (_cache[0] = _createElementVNode("p", null, "Datas de Leitura:", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, " Anterior " + _toDisplayString(_ctx.invoice.measurement_dates?.previous), 1),
        _createElementVNode("p", null, " Atual " + _toDisplayString(_ctx.invoice.measurement_dates?.current), 1),
        _createElementVNode("p", null, " Próxima " + _toDisplayString(_ctx.invoice.measurement_dates?.next), 1)
      ])
    ])
  ]))
}
}

})