import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";

import RecaptchaWrapper from "@/components/organisms/RecaptchaWrapper.vue";
import LoadingScreen from "@/components/organisms/LoadingScreen.vue";

import {
  useUserStore,
  usePersistentAuthStore,
  useWarningStore,
} from "@/stores";
import {
  setAppStarted,
  emitNotification,
  listenNotificationFromApp,
} from "./utils/appComunication";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const router = useRouter();
const userStore = useUserStore();
const persistentAuthStore = usePersistentAuthStore();
const warningStore = useWarningStore();

onBeforeMount(() => {
  userStore.loadingScreen = false;

  listenNotificationFromApp("appStarted", () => {
    setAppStarted(true);
  });

  const stores = {
    "@metha-energia/persist-session": persistentAuthStore,
    "@metha-energia/adc-warnings": warningStore,
  };

  const storesNames = Object.keys(stores);

  listenNotificationFromApp(
    "recoverStoreFromAndroid",
    (store: { key: string; content: string }) => {
      const data = JSON.parse(store.content);

      if (storesNames.includes(store.key)) {
        const key = store.key as keyof typeof stores;
        stores[key].getStoreFromMobile(data);
      }
    }
  );

  emitNotification("websiteIsLoaded");

  listenNotificationFromApp("redirectToPage", (pathToRedirect: string) => {
    router.push(pathToRedirect);
  });

  storesNames.forEach((storeName) => {
    const key = storeName as keyof typeof stores;
    stores[key].$subscribe((_, state) => {
      emitNotification("updateStore", {
        key: storeName,
        content: JSON.stringify(state),
      });
    });
  });
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RecaptchaWrapper, null, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$route.meta.layout || 'div'), null, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      ]),
      _: 1
    }),
    (_unref(userStore).loadingScreen)
      ? (_openBlock(), _createBlock(LoadingScreen, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})