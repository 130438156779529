<script setup lang="ts">
import { onBeforeMount, ref, computed } from "vue";
import { fillEmptySlotsInConsumptionHistory } from "@/utils/consumptionHistory";
import StackedBarChart from "@/components/molecules/StackedBarChart.vue";

const props = defineProps<{
  labels: Array<number | string>;
  primaryColor?: string;
  primaryValues: Array<number | string>;
  secondaryColor?: string;
  secondaryValues: Array<number | string>;
}>();

let chartPayload;

const labels = ref([]);
const methaValues = ref([]);
const supplierValues = ref([]);

const methaColor = computed(() => {
  return props.primaryColor ?? "#FFC300";
});

const supplierColor = computed(() => {
  return props.secondaryColor ?? "#E9E9E9";
});

onBeforeMount(() => {
  chartPayload = {
    labels: props.labels,
    methaConsumptionValues: props.primaryValues ?? [],
  };

  if (props.secondaryValues?.length > 0) {
    chartPayload = {
      ...chartPayload,
      supplierConsumptionValues: props.secondaryValues ?? [],
    };
  }

  if (chartPayload.labels.length > 0 && chartPayload.labels.length < 6) {
    chartPayload = fillEmptySlotsInConsumptionHistory(chartPayload);
  }

  labels.value = chartPayload.labels;
  methaValues.value = chartPayload.methaConsumptionValues;
  supplierValues.value = chartPayload.supplierConsumptionValues;
});
</script>

<template>
  <div class="past-injections-graph">
    <h3 class="past-injections-graph__title">
      Consumo em kWh dos últimos meses
    </h3>

    <StackedBarChart
      :labels="labels"
      primaryTitle="ENERGIA LIMPA METHA"
      :primaryValues="methaValues"
      :primaryColor="methaColor"
      secondaryTitle="ENERGIA CONCESSIONÁRIA"
      :secondaryValues="supplierValues"
      :secondaryColor="supplierColor"
    />
  </div>
</template>

<style lang="scss" scoped>
.past-injections-graph {
  width: 100%;

  &__title {
    font-weight: 500;
    text-align: left;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
</style>
