import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoices-blocked-by-installation-status__container" }
const _hoisted_2 = { class: "invoices-blocked-by-installation-status__icon" }
const _hoisted_3 = ["src"]

import ChatInfo from "@/assets/icons/chart-info.svg";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesBlockedByInstallationStatus',
  props: { margin: Boolean },
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'invoices-blocked-by-installation-status',
      {
        'invoices-blocked-by-installation-status--margin': __props.margin,
      },
    ])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", { src: _unref(ChatInfo) }, null, 8, _hoisted_3)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "invoices-blocked-by-installation-status__text" }, [
        _createElementVNode("p", null, [
          _createElementVNode("b", null, "Essa instalação está cancelada."),
          _createElementVNode("br"),
          _createElementVNode("br"),
          _createTextVNode(" O histórico de faturas não está disponível devido a uma instabilidade, para ter acesso entre em contato através do chat ")
        ])
      ], -1))
    ])
  ], 2))
}
}

})