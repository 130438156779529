<script setup lang="ts">
import { computed } from "vue";

import { Invoice } from "@/types/InvoiceTypes";

import { formatTwoDecimalPlaces } from "@/utils/format";

const props = defineProps<{
  invoice: Invoice;
}>();

const overrun = computed(() => {
  if (typeof props.invoice.overrun !== "number") return "Não informado";

  return formatTwoDecimalPlaces(props.invoice.overrun);
});
</script>

<template>
  <div class="invoice-overrun">
    <p class="invoice-overrun__subtitle">
      Saldo atual de geração:
      <span class="invoice-overrun__title">
        {{ overrun }}
      </span>
    </p>
    <p class="invoice-overrun__description">
      energia enviada para você mas que não foi consumida
    </p>
  </div>
</template>

<style lang="scss" scoped>
.invoice-overrun {
  padding: 1.5rem 2rem;
  border: 1px solid #afafaf;
  border-radius: 1rem;

  &__subtitle {
    color: #495057;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;

    margin-bottom: 0.5rem;
  }

  &__title {
    color: #000;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.022rem;
  }

  &__description {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
}
</style>
