<script setup lang="ts">
import { onMounted, ref } from "vue";
import Glide from "@glidejs/glide";

const glide = ref();
const maxSlides = ref(0);
const currentSlide = ref(0);

const gliderOptions: Partial<Glide.Options> = {
  type: "slider",
  rewind: false,
};

onMounted(() => {
  glide.value = new Glide(".glide", gliderOptions);

  glide.value.on("run", (eventParam: { direction: "<" | ">" }) => {
    currentSlide.value += eventParam.direction === "<" ? -1 : 1;
  });

  glide.value.mount();

  const container = document.querySelector(".glide__slides");

  const children = container?.children ?? [];

  if (children.length > 0) {
    maxSlides.value = children.length;
    for (let i = 0; i < children.length; i += 1) {
      children[i].classList.add(".glide__slide");
    }
  }
});
</script>

<template>
  <div class="swiper-container">
    <div class="glide">
      <div class="glide__track" data-glide-el="track">
        <div class="glide__slides">
          <slot></slot>
        </div>
      </div>
    </div>
    <div
      class="swiper-container__prev-slide"
      @click="glide.go('<')"
      v-if="currentSlide !== 0"
    >
      <img
        src="@/assets/icons/chevron-left-circled.svg"
        alt="Voltar"
        title="Voltar"
      />
    </div>
    <div
      class="swiper-container__next-slide"
      @click="glide.go('>')"
      v-if="currentSlide !== maxSlides - 1"
    >
      <img
        src="@/assets/icons/chevron-right-circled.svg"
        alt="Próximo"
        title="Próximo"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@glidejs/glide/dist/css/glide.core.min.css";

.swiper-container {
  position: relative;

  &__next-slide,
  &__prev-slide {
    width: 24px;
    height: 24px;
    object-fit: contain;
    object-position: center;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__next-slide {
    right: 4px;
  }

  &__prev-slide {
    left: 4px;
  }
}
</style>
