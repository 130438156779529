import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "installation-card" }

import { computed } from "vue";
import { FlatInstallation } from "@/types/InstallationTypes";

import InstallationInfo from "@/components/molecules/InstallationInfo.vue";
import InstallationCredential from "@/components/molecules/InstallationCredential.vue";
import SignContractActivator from "@/components/atoms/SignContractActivator.vue";
import InstallationContract from "@/components/atoms/InstallationContract.vue";

import { canSignContract } from "@/utils/contract";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationCard',
  props: {
    installation: {}
  },
  setup(__props: any) {

const props = __props;

const mostRecentContract = computed(() => {
  const contracts = props.installation.contracts ?? [null];
  return contracts[0];
});

const installationStatusAllowedToUpdateCredentials = computed(() => {
  const allowedStatus = ["active", "approved", "under_analysis", "suspended"];
  return allowedStatus.includes(props.installation.status);
});

const supplierAllowedToUpdateCredentials = computed(() => {
  const allowedSuppliers = ["cemig"];
  return allowedSuppliers.includes(props.installation.supplier);
});

function isPendingCredentials(installation: FlatInstallation) {
  if (typeof installation.pending_credentials === "boolean") {
    return installation.pending_credentials;
  }

  return true;
}

const needContractUpdate = computed(() => {
  return canSignContract(props.installation);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstallationInfo, {
      status: _ctx.installation.status,
      installationNumber: _ctx.installation.installation_number,
      address: _ctx.installation.address,
      supplier: _ctx.installation.supplier
    }, null, 8, ["status", "installationNumber", "address", "supplier"]),
    (mostRecentContract.value)
      ? (_openBlock(), _createBlock(InstallationContract, {
          key: 0,
          contract: mostRecentContract.value
        }, null, 8, ["contract"]))
      : _createCommentVNode("", true),
    (needContractUpdate.value)
      ? (_openBlock(), _createBlock(SignContractActivator, {
          key: 1,
          installation: _ctx.installation
        }, null, 8, ["installation"]))
      : (
        isPendingCredentials(_ctx.installation) &&
        supplierAllowedToUpdateCredentials.value &&
        installationStatusAllowedToUpdateCredentials.value
      )
        ? (_openBlock(), _createBlock(InstallationCredential, {
            key: 2,
            installation: _ctx.installation
          }, null, 8, ["installation"]))
        : _createCommentVNode("", true)
  ]))
}
}

})