import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";

import { Invoice } from "@/types/InvoiceTypes";

import api from "@/api";
import { useAuthStore } from "./auth";
import { orderByReferenceMonth } from "@/utils/orderInvoices";

const initialInvoicesState = () => ({
  invoices: [] as Invoice[],
  selectedInvoice: {} as Invoice,
});

export const useInvoicesStore = defineStore("invoices", {
  state: () =>
    useSessionStorage("@metha-energia/adc-invoices", initialInvoicesState()),
  actions: {
    async clear() {
      Object.assign(this, initialInvoicesState());
    },
    setInvoices(invoices: Invoice[]) {
      this.invoices = invoices;
    },
    setSelectedInvoice(invoice: Invoice) {
      this.selectedInvoice = invoice;
    },
    async getAllInvoices(parent_id: string | number) {
      if (!parent_id) return;

      const authStore = useAuthStore();
      const token = await authStore.getToken("invoicesStoreGetAllInvoices");

      const { data } = await api.invoice.getAllInvoices(
        parent_id,
        {},
        {
          headers: {
            "g-recaptcha-response": token,
          },
        }
      );

      this.setInvoices(data.invoices);
    },
    async getInvoiceById(id: string) {
      const authStore = useAuthStore();
      const token = await authStore.getToken("invoicesStoreGetInvoiceById");

      const { data } = await api.invoice.getInvoiceById(
        id,
        {},
        { headers: { "g-recaptcha-response": token } }
      );

      this.setSelectedInvoice(data[0]);
    },
    getInvoiceByReferenceMonth(referenceMonth: string) {
      if (referenceMonth === this.selectedInvoice.reference_month) {
        return;
      }

      const invoice = this.invoices.find(
        (invoice) => invoice.reference_month === referenceMonth
      );

      return invoice;
    },
    getInvoiceHistoryConsumptionChartInfo() {
      if (this.invoices.length === 0)
        return {
          labels: [],
          methaValues: [],
          supplierValues: [],
        };

      const orderedInvoices = orderByReferenceMonth(this.invoices, "ascending");

      const labels: string[] = [];
      const methaValues: Array<number | string> = [];
      const supplierValues: Array<number | string> = [];

      orderedInvoices
        .slice(0, 6)
        .forEach(
          (invoice: {
            kwh_injected: number;
            kwh_consumption: number;
            reference_month: string;
          }) => {
            const { kwh_injected, kwh_consumption, reference_month } = invoice;

            const [invoiceMonth] = reference_month.split("/");
            const supplierConsumption = kwh_consumption - kwh_injected;

            labels.push(invoiceMonth);
            methaValues.push(kwh_injected);
            supplierValues.push(supplierConsumption);
          }
        );

      return {
        labels,
        methaValues,
        supplierValues,
      };
    },
  },
});
