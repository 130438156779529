<script setup lang="ts">
import { useBreakpoints } from "@/libs/metha-components";

import SwiperContainer from "@/components/molecules/SwiperContainer.vue";

const { lgAndUp } = useBreakpoints();
</script>

<template>
  <SwiperContainer v-if="!lgAndUp">
    <slot></slot>
  </SwiperContainer>
  <slot v-else></slot>
</template>
